// import React, { useContext, useMemo } from 'react';
// import {
//   CircularProgressbar,
//   buildStyles,
// } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import './ComponentDashboardCard.scss';
// import { Grid } from '@mui/material';
// import { TypeChartProps } from './utils/TypeDashboard';
// import { LocalizationContext } from '../../Common/Localization/LocalizationProvider';

// function DashboardChart({ oilKitOrders, freonOrders }: TypeChartProps) {
//   const { translations } = useContext(LocalizationContext);

//   const calOilKitOrderPercentage = useMemo(() => {
//     const totalOrders = oilKitOrders + freonOrders;
//     if (totalOrders) {
//       const oilKitPercentage = (oilKitOrders / totalOrders) * 100;
//       return oilKitPercentage?.toFixed(2);
//     }
//     return 0;
//   }, [oilKitOrders, oilKitOrders]);

//   const calFreonOrderPercentage = useMemo(() => {
//     const totalOrders = oilKitOrders + freonOrders;
//     if (totalOrders) {
//       const oilKitPercentage = (freonOrders / totalOrders) * 100;
//       return oilKitPercentage?.toFixed(2);
//     }
//     return 0;
//   }, [oilKitOrders, oilKitOrders]);

//   const oilKitsReceived = useMemo(() => `${oilKitOrders} - Received`, [oilKitOrders]);
//   const freonReceived = useMemo(() => `${freonOrders} - Received`, [freonOrders]);

//   return (
//     <Grid container spacing={2}>
//       <Grid item md={12} sm={12} xs={12}>
//         <div className="dashboardCircularbarWrapper">
//           <div className="dashboardHeader">
//             <div className="dashboardCircularbarText">{translations.numberOfOrder}</div>
//           </div>
//           <div className="divider" />
//           <div className="dashboardCircularbarAlign">
//             <div className="dashboardCircularbar">
//               <CircularProgressbar
//                 value={Number(calFreonOrderPercentage)}
//                 strokeWidth={50}
//                 styles={buildStyles({
//                   strokeLinecap: 'butt',
//                   pathColor: '#D74B8E',
//                   trailColor: '#6A68C5',
//                 })}
//               />
//             </div>
//           </div>
//           <div className="graphBottomData">
//             <div className="buttomWrapper">
//               <div className="flex">
//                 <div className="rectangular" />
//                 <div className="buttomWrapperText">{translations.oilKits}</div>
//               </div>
//               <div className="buttomWrapperSubText">{oilKitsReceived}</div>
//               <div className="greenText">{`${calOilKitOrderPercentage}%`}</div>
//             </div>
//             <div className="buttomWrapper">
//               <div className="flex">
//                 <div className="rectangularLightBlue" />
//                 <div className="buttomWrapperText">{translations.freonCylinders}</div>
//               </div>
//               <div className="buttomWrapperSubText ml">{freonReceived}</div>
//               <div className="greenText">{`${calFreonOrderPercentage}%`}</div>
//             </div>
//           </div>
//         </div>
//       </Grid>
//     </Grid>
//   );
// }
// export default React.memo(DashboardChart);

import React, {
  SetStateAction, useContext, useMemo, useState,
} from 'react';
import { Grid } from '@mui/material';
import {
  PieChart, Pie, Sector, ResponsiveContainer,
} from 'recharts';
import './ComponentDashboardCard.scss';
import { TypeChartProps } from './utils/TypeDashboard';
import { LocalizationContext } from '../../Common/Localization/LocalizationProvider';
import { Empty } from '../ScreenAddSmc/Utils/TypeSmc';

const renderActiveShape = (props: Empty) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize="12">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

function DashboardChart({ oilKitOrders, freonOrders }: TypeChartProps) {
  const { translations } = useContext(LocalizationContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_: string, index: SetStateAction<number>) => {
    setActiveIndex(index);
  };

  const chartData = useMemo(
    () => [
      { name: translations.oilKits, value: oilKitOrders, fill: 'purple' },
      { name: translations.freonCylinders, value: freonOrders, fill: '#9996DD' },
    ],
    [oilKitOrders, freonOrders, translations],
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <div className="dashboardCircularbarWrapper">
          <div className="dashboardHeader">
            <div className="dashboardCircularbarText">{translations.numberOfOrder}</div>
          </div>
          <div className="divider" />
          <div className="dashboardCircularbarAlign">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={chartData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="graphBottomData">
            {chartData.map((item) => (
              <div key={item.name} className="buttomWrapper">
                <div className="flex">
                  <div
                    className="rectangular"
                    style={{ backgroundColor: item.fill }}
                  />
                  <div className="buttomWrapperText">{item.name}</div>
                </div>
                <div className="buttomWrapperSubText">{`${item.value} - Received`}</div>
                <div className="greenText">{`${((item.value / (oilKitOrders + freonOrders)) * 100).toFixed(2)}%`}</div>
              </div>
            ))}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default React.memo(DashboardChart);
