import React, { useEffect, useMemo } from 'react';
import {
  Button, Grid, Tab, Tabs,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { EQUIPMENT_NEEDED, HISTORY, REQUEST } from '../utils/constants';
import ComponentHistory from './Components/ComponentHistory/ComponentHistory';
import ComponentEquipmentNeed from './Components/ComponentEquipmentNeed/ComponentEquipmentNeed';
import ComponentRequest from './Components/ComponentRequest/ComponentRequest';
import { RequestMainFormValues } from './utils/types';
import {
  createEquipmentRequestStart, equipmentRequestRejected,
  getEquipmentMasterStatusStart, resetInventoryEquipmentRequest, setAdminNote, setEquipmentRequestTab,
} from './redux/equipmentRequestSlice';
import { RootState } from '../../../redux/rootState';
import { DropdownArray } from './redux/type';
import '../Style.scss';
import { RequestFormFields, statusVariable } from './utils/constants';
import EndPoints from '../../../Routes/EndPoints';
import { JobIcon } from '../../../assets/NavBarIcons';
import ComponentBreadcrumb from '../../../Common/ComponentBreadcrumb/ComponentBreadcrumb';
import { gaugeToInch } from '../../../utils/CommonFunctions';

export default function TestEquipmentRequestScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestId, roleValue } = useParams();
  const {
    control, handleSubmit, formState: { errors }, watch, setValue, getValues, reset,
  } = useForm<RequestMainFormValues>();
  const adminNotes = getValues(RequestFormFields.note);
  const isVertical = getValues(RequestFormFields.vertical);
  const isAdmin = requestId && roleValue;

  const { selectedRowId, comment, equipmentRequestTab } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);
  const {
    inventoryEquipmentMasterStatusData,
    equipmentDetailsByRequestIdData, adminNote, isEquipmentRequestRejected,
  } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const changeEventStatus = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setEquipmentRequestTab(newValue));
  };

  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  useEffect(() => {
    dispatch(getEquipmentMasterStatusStart());
    return () => {
      dispatch(resetInventoryEquipmentRequest());
      dispatch(setAdminNote(''));
    };
  }, []);

  const goToInventory = () => {
    navigate(`/${EndPoints.INVENTORY_MANAGEMENT}`);
  };

  useEffect(() => {
    if (isEquipmentRequestRejected) {
      dispatch(equipmentRequestRejected(false));
      navigate(-1);
    }
  }, [isEquipmentRequestRejected]);

  const approveEquipmentRequest = () => {
    const onApprovedStatus = inventoryEquipmentMasterStatusData?.find((ele: DropdownArray) => ele?.Text === 'Approved');
    const data = getValues();
    if (requestId) {
      const payload = {
        RequestId: equipmentDetailsByRequestIdData?.RequestId,
        UnitId: equipmentDetailsByRequestIdData?.UnitId,
        JobId: equipmentDetailsByRequestIdData?.JobId,

        VesselId: Number(data?.vessel) || null,
        TubeId: Number(data?.tubeType) || null,
        MaterialId: Number(data?.material) || null,
        OuterDiameter: Number(data?.OD) || 0,
        InnerDiameter: Number(data?.ID) || 0,

        Nwt: data.units ? gaugeToInch(data?.NWT) : data?.NWT || '',
        NwtBellLand: data.units ? gaugeToInch(data?.NWTBellLand) : data?.NWTBellLand || '',
        NwtUnderFills: data.units ? gaugeToInch(data?.NWTUnderFins) : data?.NWTUnderFins || '',

        WorkHrs: equipmentDetailsByRequestIdData?.WorkHours || 0,
        RetestOf: equipmentDetailsByRequestIdData?.RetestOf || '',
        Note: getHtmlFromEditorHTMLtoString(comment) || '',
        Vertical: isVertical || false,
        Status: Number(onApprovedStatus?.Value),
        AdminComments: adminNotes || '',
      };
      dispatch(createEquipmentRequestStart({
        payload, navigate, isAdmin: true, status: statusVariable.APPROVED,
      }));
    }
  };

  const onSubmit = (data: RequestMainFormValues) => {
    if (requestId) {
      approveEquipmentRequest();
      return;
    }
    const onCreateStatus = inventoryEquipmentMasterStatusData?.find((ele: DropdownArray) => ele?.Text === 'Approved');
    const extractedNumber = selectedRowId?.split('-')[0];
    const payload = {
      UnitId: selectedRowId,
      JobId: extractedNumber,
      VesselId: Number(data?.vessel) || null,
      TubeId: Number(data?.tubeType) || null,
      MaterialId: Number(data?.material) || null,
      OuterDiameter: Number(data?.OD) || 0,
      InnerDiameter: Number(data?.ID) || 0,
      Nwt: data.units ? gaugeToInch(data?.NWT) : data?.NWT || '',
      NwtBellLand: data.units ? gaugeToInch(data?.NWTBellLand) : data?.NWTBellLand || '',
      NwtUnderFills: data.units ? gaugeToInch(data?.NWTUnderFins) : data?.NWTUnderFins || '',
      WorkHrs: data?.workHrs || 0,
      RetestOf: data?.retestOf || '',
      Note: getHtmlFromEditorHTMLtoString(comment) || '',
      Vertical: data?.vertical || false,
      Status: Number(onCreateStatus?.Value),
      AdminComments: adminNotes,
    };
    dispatch(createEquipmentRequestStart({
      payload, navigate, isAdmin: true, status: statusVariable.CREATED,
    }));
  };

  const onReject = () => {
    const onRejectedStatus = inventoryEquipmentMasterStatusData?.find((ele: DropdownArray) => ele?.Text === 'Rejected');
    const payload = {
      RequestId: equipmentDetailsByRequestIdData?.RequestId,
      UnitId: equipmentDetailsByRequestIdData?.UnitId,
      JobId: equipmentDetailsByRequestIdData?.JobId,
      VesselId: equipmentDetailsByRequestIdData?.VesselId || null,
      TubeId: equipmentDetailsByRequestIdData?.TubeId || null,
      MaterialId: equipmentDetailsByRequestIdData?.MaterialId || null,
      OuterDiameter: equipmentDetailsByRequestIdData?.OuterDiameter || 0,
      InnerDiameter: equipmentDetailsByRequestIdData?.InnerDiameter || 0,
      Nwt: equipmentDetailsByRequestIdData?.Nwt || '',
      NwtBellLand: equipmentDetailsByRequestIdData?.NwtBellLand || '',
      NwtUnderFills: equipmentDetailsByRequestIdData?.NwtUnderfils || '',
      WorkHrs: equipmentDetailsByRequestIdData?.WorkHours || 0,
      RetestOf: equipmentDetailsByRequestIdData?.RetestOf || '',
      Note: equipmentDetailsByRequestIdData?.Notes || '',
      Vertical: isVertical || false,
      Status: Number(onRejectedStatus?.Value),
      AdminComments: adminNotes || '',
    };
    dispatch(createEquipmentRequestStart({
      payload, navigate, isAdmin: true, status: statusVariable.REJECTED,
    }));
  };

  const vesselValue = watch(RequestFormFields.vessel);
  const tubeTypeValue = watch(RequestFormFields.tubeType);
  const materialValue = watch(RequestFormFields.material);

  const disableSaveButton = useMemo(() => {
    if (vesselValue && tubeTypeValue && materialValue && adminNote !== '') {
      return false;
    }
    return true;
  }, [vesselValue, tubeTypeValue, materialValue, adminNote]);

  const getAdminButton = () => {
    if (isAdmin) {
      return (
        <div className="button_margin_left">
          <Button
            className={disableSaveButton ? 'disableBtn' : 'button_save_and_next'}
            disabled={disableSaveButton}
            type="submit"
          >
            Approve
          </Button>
        </div>
      );
    }
    return (
      <div className="button_margin_left">
        <Button
          className={disableSaveButton ? 'disableBtn' : 'button_save_and_next'}
          disabled={disableSaveButton}
          type="submit"
        >
          Save
        </Button>
      </div>
    );
  };

  function showComponentListingDetails(): JSX.Element {
    if (equipmentRequestTab === REQUEST) {
      return (
        <div className="div_container_CustomerInfo">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <ComponentRequest reset={reset} control={control} errors={errors} watch={watch} setValue={setValue} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button onClick={goToInventory} className="button_cancel">Cancel</Button>
                  </div>
                  {isAdmin && (
                    <div className="button_margin_left">
                      <Button
                        disabled={disableSaveButton}
                        onClick={onReject}
                        className={disableSaveButton ? 'disableBtn' : 'rejectBtn'}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                  {getAdminButton()}
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      );
    } if (equipmentRequestTab === EQUIPMENT_NEEDED) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentEquipmentNeed />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className="div_container_CustomerInfo">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <ComponentHistory />
          </Grid>
        </Grid>
      </div>
    );
  }

  const { JobId, BidId, UnitId }: { JobId: string, BidId: string, UnitId: string } = equipmentDetailsByRequestIdData;
  const breadcrumbs = [
    { label: 'Job', url: `/jobs/${JobId || ''}`, icon: <JobIcon /> },
    { label: 'Bids', url: `/jobs/bid-worksheet/${BidId || ''}`, icon: <JobIcon /> },
    { label: 'Units', url: `/unit/${UnitId || ''}`, icon: <JobIcon /> },
    { label: 'Equipments', url: '/test-request', icon: <JobIcon /> },
  ];

  return (
    <>
      <ComponentPageHeader subHeading="TEST EQUIPMENT REQUEST" />
      {requestId && <ComponentBreadcrumb crumbs={breadcrumbs} /> }
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={equipmentRequestTab}
          onChange={changeEventStatus}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label="Request"
            style={equipmentRequestTab === REQUEST ? selectedTabStyles : tabStyles}
            value={REQUEST}
            className="tab_styles"
          />
          <Tab
            label="Equipment Needed"
            style={equipmentRequestTab === EQUIPMENT_NEEDED ? selectedTabStyles : tabStyles}
            className="tab_styles"
            disabled={!requestId}
            value={EQUIPMENT_NEEDED}
          />
          <Tab
            label="Activity History"
            style={equipmentRequestTab === HISTORY ? selectedTabStyles : tabStyles}
            className="tab_styles"
            value={HISTORY}
            disabled={!requestId}
          />
        </Tabs>
      </div>
      <div className={requestId ? 'div_job_new' : 'div_job_container_info'}>
        {showComponentListingDetails()}
      </div>
    </>
  );
}
