import React, { useEffect } from 'react';
import { Button, Grid, Card } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useNavigate } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../utils/RequiredField';
import { createTreeDataStart, getColorDataStart, resetDefectState } from './redux/sliceDefectCode';
import { RootState } from '../../../redux/rootState';
import NewFolderTree from './components/NewFolderTree';
import { ContactDefaultValue, DefectCodeFormValue, addDefectCodeSchema } from './utils/helper';
import { AddDefectCodeForm, FormData } from './utils/TypeAnalystMenu';
import { setLoader } from '../../../redux/loaderSlice';

function AnalystDefectMenu() {
  const {
    control, handleSubmit, formState: { errors }, setValue, reset,
  } = useForm<AddDefectCodeForm>({
    defaultValues: ContactDefaultValue,
    resolver: yupResolver(addDefectCodeSchema as ObjectSchema<AddDefectCodeForm>),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { treeData, isLoading } = useSelector((state: RootState) => state.defectCodeReducer);
  useEffect(() => {
    dispatch(getColorDataStart());
    return () => {
      dispatch(resetDefectState());
    };
  }, []);

  const onSubmit = (data: FormData) => {
    const addDefectPayload = {
      Type: 1,
      Id: 0,
      ParentId: treeData?.Id,
      TypeId: 104,
      Value: data.Code,
      Code: data.Code,
      Color: data.Color,
      Description: data.Description,
      AdditionalDescription: data.AdditionalDescription,
      ActionRequired: data.ActionCheckBox,
    };
    dispatch(createTreeDataStart(addDefectPayload));
  };

  useEffect(() => {
    reset();
    if (treeData?.Children?.length === 0) {
      setValue(DefectCodeFormValue.Category, treeData?.CategoryName);
      setValue(DefectCodeFormValue.AdditionalDescription, treeData?.AdditionalDescription);
      setValue(DefectCodeFormValue.SubCategory, treeData?.SubCategoryName);
      setValue(DefectCodeFormValue.Description, treeData?.Name);
      setValue(DefectCodeFormValue.Code, treeData?.Code);
      setValue(DefectCodeFormValue.Color, treeData?.Color);
      setValue(DefectCodeFormValue.ActionCheckBox, treeData?.ActionRequired);
    } else if (treeData?.Children?.length === 1) {
      setValue(DefectCodeFormValue.Category, treeData?.CategoryName);
      setValue(DefectCodeFormValue.AdditionalDescription, treeData?.Children[0]?.AdditionalDescription);
      setValue(DefectCodeFormValue.SubCategory, treeData?.SubCategoryName);
      setValue(DefectCodeFormValue.Code, treeData?.Children[0]?.Code);
      setValue(DefectCodeFormValue.Description, treeData?.Name);
      setValue(DefectCodeFormValue.Color, String(treeData?.Children[0]?.Color));
      setValue(DefectCodeFormValue.ActionCheckBox, treeData?.ActionRequired);
    } else if (treeData?.TypeId === 104) {
      setValue(DefectCodeFormValue.Category, treeData?.CategoryName);
      setValue(DefectCodeFormValue.AdditionalDescription, treeData?.AdditionalDescription);
      setValue(DefectCodeFormValue.SubCategory, treeData?.SubCategoryName);
      setValue(DefectCodeFormValue.Description, treeData?.Description || '');
      setValue(DefectCodeFormValue.Code, treeData?.Code);
      setValue(DefectCodeFormValue.Color, treeData?.Color);
      setValue(DefectCodeFormValue.ActionCheckBox, treeData?.ActionRequired);
    }
  }, [treeData]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDrag = () => {
    navigate('category');
  };

  return (
    <>
      <ComponentPageHeader subHeading="ANALYST DEFECT CODE" buttonName="Re-Arrange" handleButtonClick={handleDrag} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboardCardWrapper div_themes_container">
          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12}>
              <Card>
                <div className="defectContainer">
                  <NewFolderTree />
                </div>
              </Card>
            </Grid>
            {treeData?.SubCategoryName ? (
              <Grid item md={7} sm={12} xs={12}>
                <Card>
                  <div className="defectContainer">
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12} className="defect_action_checkbox">
                        <CustomCheckbox
                          isDisable={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                          name={DefectCodeFormValue.ActionCheckBox}
                          control={control}
                          label="Action required"
                        />
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className="label_Style_disabled">
                            Category
                          </label>
                          <ComponentTextInput
                            control={control}
                            isDisable
                            name={DefectCodeFormValue.Category}
                            id={DefectCodeFormValue.Category}
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className="label_Style_disabled">
                            Sub Category
                          </label>
                          <ComponentTextInput
                            control={control}
                            isDisable
                            name={DefectCodeFormValue.SubCategory}
                            id={DefectCodeFormValue.SubCategory}
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className={treeData?.TypeId === 104 || treeData?.Children?.length === 1
                            ? 'label_Style_disabled' : 'label_Style_Customer'}
                          >
                            Code
                            <RequiredFiled />
                          </label>
                          <ComponentTextInput
                            control={control}
                            errors={errors}
                            inputProps={{ maxLength: 3 }}
                            className="nonCapitalizeField"
                            isDisable={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                            name={DefectCodeFormValue.Code}
                            id={DefectCodeFormValue.Code}
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className={treeData?.TypeId === 104 || treeData?.Children?.length === 1
                            ? 'label_Style_disabled' : 'label_Style_Customer'}
                          >
                            Color
                            <RequiredFiled />
                          </label>
                          <Controller
                            control={control}
                            name={DefectCodeFormValue.Color}
                            render={({ field: { onChange, value } }) => (
                              <input
                                type="color"
                                name={DefectCodeFormValue.Color}
                                disabled={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                                onChange={(event) => {
                                  onChange(event);
                                }}
                                value={value}
                                className="colorPickerStyle"
                              />
                            )}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className={treeData?.TypeId === 104 || treeData?.Children?.length === 1
                            ? 'label_Style_disabled' : 'label_Style_Customer'}
                          >
                            Description
                            <RequiredFiled />
                          </label>
                          <ComponentTextInput
                            control={control}
                            className="nonCapitalizeField"
                            isDisable={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                            errors={errors}
                            inputProps={{ maxLength: 50 }}
                            name={DefectCodeFormValue.Description}
                            id={DefectCodeFormValue.Description}
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className={treeData?.TypeId === 104 || treeData?.Children?.length === 1
                            ? 'label_Style_disabled' : 'label_Style_Customer'}
                          >
                            Additional Description
                            {/* <RequiredFiled /> */}
                          </label>
                          <ComponentTextInput
                            control={control}
                            className="nonCapitalizeField"
                            isDisable={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                            errors={errors}
                            inputProps={{ maxLength: 50 }}
                            name={DefectCodeFormValue.AdditionalDescription}
                            id={DefectCodeFormValue.AdditionalDescription}
                            size="small"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
                <div className="Save_next_defect_code">
                  <div className="button_margin_left">
                    <Button
                      type="submit"
                      className="button_save_and_next"
                      disabled={treeData?.TypeId === 104 || treeData?.Children?.length === 1}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid md={7} sm={12} xs={12} />
            )}
          </Grid>
        </div>
      </form>
    </>
  );
}

export default AnalystDefectMenu;
