/* eslint-disable no-plusplus */
import { format, getISOWeek } from 'date-fns';
import dayjs from 'dayjs';
import { generateRandomId, getTotalDaysInMonth } from '../../../utils/CommonFunctions';
import {
  AddBookmarkFields,
  AddEventFields,
  AppointmentInfo,
  BookMarks,
  CalendarDataProps, CalendarDayInterface, CalendarEventInterface, CalendarWeekDayDetails, FormatMonthData,
} from './types';
import styles from '../index.module.scss';
import { gridClassname } from './constant';
import { Entries } from '../../../Common/ComponentSelectInput/types';
import { IconList } from '../../../utils/StaticIcons';

const todayDate = format(new Date(), 'dd-MM-yyyy');

function createArrayWithNumbers(n:number) {
  return Array.from({ length: n }, (_, index) => index + 1);
}
// function to get the calemdar grid data array
export function getCalendarsData(props:CalendarDataProps) {
  const {
    selectedYear, // year selected by user
    selectedMonth, // month selected by the month
    eventList, // all events data coming from api
    analystList, // list of all analyst
    publicHolidays, // public holidays data coming from api
    weekDays, // day wise data fro a week
    weekWiseData, // boolean to decide whether to calculate data in weekWise or monthWise view
  } = props;

  const totalDays = getTotalDaysInMonth(Number(selectedYear), Number(selectedMonth));

  // constructing data for all days with initial values
  const constructDaysArray = () => {
    const days:CalendarDayInterface[] = [];
    let dayArray:number[];

    // assigning value to dayArray with dates included in request (e.g 7 for week & 30 for days)
    if (weekDays && weekDays.length > 0) {
      dayArray = weekDays.map((dayData:string) => Number(dayData.split('/')?.[1]));
    } else {
      dayArray = createArrayWithNumbers(totalDays);
    }

    // Iterate over dayArray to give initial objects with inital values
    for (let i = 0; i < dayArray.length; i++) {
      const currDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, dayArray[i]);
      const formattedDate = format(currDate, 'dd-MM-yyyy');
      let className = '';
      if (publicHolidays.findIndex((holiday) => Number(holiday.Date.split('-')?.[0]) === dayArray[i]) > -1) {
        className = styles.calendarRedBGColor;
      } else if (formattedDate === todayDate) {
        className = styles.calendarGreenBGColor;
      }
      const obj = {
        eventId: -1, // event Id for the ith date
        showIcon: false, // boolean for whether to show icon on ith day or not
        day: dayArray[i], // date for the ith day
        className, // classname
        unitList: [], // list of all the units if the event is job
        eventInfoList: [], // list of events on ith day
        uniqueId: generateRandomId(), // unique id for every object
        date: formattedDate, // formated Date for ith day
      };

      days.push(obj);
    }

    return days;
  };
  // function to get the index for the specific date from weekDays array
  const getWeekDayIndex = (val:number) => weekDays?.findIndex((day) => Number(day.split('/')?.[1]) === val + 1) ?? 0;

  // array of events details analyst wise
  const eventsData = eventList?.map((event) => {
    const entry:CalendarEventInterface = {
      analystName: event?.AnalystName,
      days: [],
      analystId: event?.AnalystId,
    };

    // initializing intial array
    const days = constructDaysArray();

    event.Events?.forEach((ele) => {
      // subtracting 1 because of 0-indexing in data
      const startDate = Number(ele.StartDate.split('-')?.[0]) - 1;
      const endDate = Number(ele.EndDate.split('-')?.[0]) - 1;

      // event deatails obj
      const appDetails = {
        analystName: event.AnalystName,
        startDate: ele.StartDate,
        endDate: ele.EndDate,
        contact: ele.Contact,
        notes: ele.Note,
        eventId: ele.EventId,
        id: ele.Id,
      };

      const jobDetails = {
        startDate: ele.StartDate,
        endDate: ele.EndDate,
        id: ele.Id,
      };

      // getting index for weekwise (if it is weekView) and updating showIcon to true
      const weekWiseInd = weekWiseData ? getWeekDayIndex(startDate) : startDate;
      if (weekWiseInd > -1) {
        days[weekWiseInd].showIcon = true;
      }

      // iterating from startDate to endDate and assiging respective values
      for (let j = (weekWiseData ? getWeekDayIndex(startDate) : startDate); j <= (weekWiseData ? getWeekDayIndex(endDate) : endDate); j++) {
        const name = gridClassname.find((classData) => ele.EventId === classData.eventId)?.className || '';
        days[j].eventId = ele.EventId;
        days[j].className = name;

        // if it is job event then push it to unitList otherwise push it to eventInfoList
        if (ele.EventId === 4) {
          days[j].unitList.push(jobDetails);
        } else {
          days[j].eventInfoList.push(appDetails);
        }
      }
    });

    entry.days = days;
    return entry;
  });

  // Iterating on analystList and mapping analyst and respective events
  return analystList?.map((analyst:Entries) => {
    const ana = eventsData?.find((ele) => ele.analystId === analyst.Value);
    let days:CalendarDayInterface[] = [];

    if (ana) {
      days = ana.days;
    } else {
      days = constructDaysArray();
    }

    return {
      analystName: String(analyst.Text),
      analystId: String(analyst.Value),
      days,
    };
  });
}

function getWeekNumber(date: number, selectedYear:string, selectedMonth: string): number {
  const currDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, date);
  return getISOWeek(currDate);
}

export function formatMonthData(props:FormatMonthData) {
  const {
    selectedMonth, selectedYear, publicHolidays, events,
    selectedAnalyst,
  } = props;

  // Total Days in Month
  const totalDays = getTotalDaysInMonth(Number(selectedYear), Number(selectedMonth));

  const days:CalendarDayInterface[][] = [];

  let weekDetails:CalendarDayInterface[] = [];

  // Array for every day in month
  const dayArray = createArrayWithNumbers(totalDays);

  const firstDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, 1);
  const firstDay = firstDate.getDay() === 0 ? 7 : firstDate.getDay();

  for (let j = 1; j < firstDay; j++) {
    const obj = {
      eventId: -1,
      showIcon: false,
      day: -1,
      className: styles.noMonthBorder,
      unitList: [],
      eventInfoList: [],
      uniqueId: generateRandomId(),
      date: '',
    };

    weekDetails.push(obj);
  }

  for (let i = 0; i < dayArray.length; i++) {
    const currDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, dayArray[i]);
    const formattedDate = format(currDate, 'dd-MM-yyyy');
    let className = '';
    if (publicHolidays.findIndex((holiday) => Number(holiday.Date.split('-')?.[0]) === dayArray[i]) > -1) {
      className = styles.calendarRedBGColor;
    } else if (formattedDate === todayDate) {
      className = styles.calendarGreenBGColor;
    }

    const obj = {
      eventId: -1,
      showIcon: false,
      day: dayArray[i],
      className,
      unitList: [],
      eventInfoList: [],
      uniqueId: generateRandomId(),
      date: formattedDate,
    };

    weekDetails.push(obj);

    if (weekDetails.length === 7) {
      days.push(weekDetails);
      weekDetails = [];
    }
  }

  days.push(weekDetails);

  events?.forEach((ele) => {
    const startDate = Number(ele.StartDate.split('-')?.[0]);
    const endDate = Number(ele.EndDate.split('-')?.[0]);

    const appDetails = {
      startDate: ele.StartDate,
      endDate: ele.EndDate,
      contact: ele.Contact,
      notes: ele.Note,
      eventId: ele.EventId,
      id: ele.Id,
      analystName: String(selectedAnalyst?.Text),
      analystId: String(selectedAnalyst?.Value),
    };

    const jobDetails = {
      startDate: ele.StartDate,
      endDate: ele.EndDate,
      id: ele.Id,
    };

    const updatedDate = startDate + firstDay - 1;

    const dayIndex = Math.floor((updatedDate - 1) / 7);
    const updatedStartIndex = (updatedDate - 1) % 7;

    days[dayIndex][updatedStartIndex].showIcon = true;

    for (let j = startDate; j <= endDate; j++) {
      const name = gridClassname.find((classData) => ele.EventId === classData.eventId)?.className || '';
      const tempDate = j + firstDay - 1;
      const tempDayIndex = Math.floor((tempDate - 1) / 7);
      const tempIndex = (tempDate - 1) % 7;

      days[tempDayIndex][tempIndex].eventId = ele.EventId;
      days[tempDayIndex][tempIndex].className = name;

      if (ele.EventId === 4) {
        days[tempDayIndex][tempIndex].unitList.push(jobDetails);
      } else {
        days[tempDayIndex][tempIndex].eventInfoList.push(appDetails);
      }
    }
  });

  return days;
}

export function getWeeksData(selectedYear: string, selectedMonth: string, bookmarks:BookMarks[]) {
  let date = 1;
  const totalDays = getTotalDaysInMonth(Number(selectedYear), Number(selectedMonth));
  const data = [];
  let currentDays:CalendarWeekDayDetails[] = [];
  let lastWeek = getWeekNumber(1, selectedYear, selectedMonth);

  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  while (date <= totalDays) {
    const currentWeek = getWeekNumber(date, selectedYear, selectedMonth);
    const currDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, date);
    const formattedDate = format(currDate, 'dd-MM-yyyy');

    const targetedDate = date;

    let className = '';
    let bookmarkId = -1;

    const bookMark = bookmarks.find((mark) => Number(mark.Date.split('-')?.[0]) === targetedDate);

    if (bookMark) {
      className = styles.bookmarkBg;
      bookmarkId = bookMark.Id;
    } else if (todayDate === formattedDate) {
      className = styles.calendarGreenTextColor;
    } else if (currDate.getDay() === 0) {
      className = styles.calendarRedTextColor;
    } else {
      className = styles.calendarBlackTextColor;
    }

    if (currentWeek === lastWeek) {
      currentDays.push({
        date,
        day: weekDays[currDate.getDay()],
        className,
        bookmarkId,
      });
    } else {
      data.push({
        weekNumber: `Week ${lastWeek}`,
        days: currentDays,
      });
      currentDays = [{
        date,
        day: weekDays[currDate.getDay()],
        className,
        bookmarkId,
      }];
      lastWeek = currentWeek;
    }

    date += 1;
  }

  data.push({
    weekNumber: currentDays.length > 3 ? `Week ${lastWeek}` : '',
    days: currentDays,
  });

  return data;
}

export function assignEventvalues(setValue:SetValue, selectedEvent:AppointmentInfo) {
  const sArr = selectedEvent?.startDate?.split('-');
  const eArr = selectedEvent?.endDate?.split('-');
  const sDate = new Date(Number(sArr[2]), Number(sArr[1]) - 1, Number(sArr[0]));
  const eDate = new Date(Number(eArr[2]), Number(eArr[1]) - 1, Number(eArr[0]));

  [
    { name: AddEventFields.analystName, value: selectedEvent.analystName },
    { name: AddEventFields.event, value: selectedEvent.eventId },
    { name: AddEventFields.startDate, value: dayjs(sDate) },
    { name: AddEventFields.endDate, value: dayjs(eDate) },
    { name: AddEventFields.contact, value: selectedEvent.contact },
    { name: AddEventFields.note, value: selectedEvent.notes },

  ].forEach(({ name, value }) => setValue(name, value));
}

export function assignBookmarkValues(setValue: SetValue, selectedBookmark: BookMarks) {
  const arr = selectedBookmark?.Date?.split('-');
  const markDate = new Date(Number(arr[2]), Number(arr[1]) - 1, Number(arr[0]));

  [
    { name: AddBookmarkFields.description, value: selectedBookmark.Description },
    { name: AddBookmarkFields.bookmarkDate, value: dayjs(markDate) },
    { name: AddBookmarkFields.customerId, value: selectedBookmark.CustomerId },
    { name: AddBookmarkFields.analystId, value: selectedBookmark.AnalystId },

  ].forEach(({ name, value }) => setValue(name, value));
}

export function getCalendarIcon(eventId: number) {
  if (eventId === 1 || eventId === 2) {
    return IconList.leaveIcon;
  }
  return IconList.jobIcon;
}

export function getDatesOfWeek(year:number, weekNumber:number) {
  const startDate = new Date(year, 0, 1);
  const firstDay = startDate.getDay();
  const daysToAdd = (weekNumber - 1) * 7 - firstDay + 1;
  const startDateOfWeek = new Date(startDate.setDate(startDate.getDate() + daysToAdd));

  const datesOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDateOfWeek);
    currentDate.setDate(startDateOfWeek.getDate() + i);
    const date = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    datesOfWeek.push(date);
  }
  return datesOfWeek;
}

export function getWeekCalendarDays(selectedYear: string, selectedWeek: number, bookmarks: BookMarks[]) {
  const dates = getDatesOfWeek(Number(selectedYear), selectedWeek);

  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return dates.map((curr) => {
    const selectedDate = Number(curr.split('/')?.[1]);
    const selectedMonth = curr.split('/')?.[0];
    const currDate = new Date(Number(selectedYear), Number(selectedMonth) - 1, selectedDate);

    let className = '';
    let bookmarkId = -1;

    const bookMark = bookmarks.find((mark) => Number(mark.Date.split('-')?.[0]) === selectedDate);

    const formattedDate = format(currDate, 'dd-MM-yyyy');

    if (bookMark) {
      className = styles.bookmarkBg;
      bookmarkId = bookMark.Id;
    } else if (todayDate === formattedDate) {
      className = styles.calendarGreenTextColor;
    } else if (currDate.getDay() === 0) {
      className = styles.calendarRedTextColor;
    } else {
      className = styles.calendarBlackTextColor;
    }

    return {
      day: weekDays[currDate.getDay()], date: selectedDate, bookmarkId, className,
    };
  });
}
