import {
  TextField, IconButton, Grid, Card, Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ICustomerSiteInfo } from '../../utils/types';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { CustomerSiteFormFields } from '../../utils/constants';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../../redux/rootState';
import AddSiteModal from './addSiteModal';
import { assignSiteValues } from '../../utils/helper';
import { extRegex, phoneRegex } from '../../../../../../utils/regex';

export default function ComponentSiteInfo({
  control, setValue, isDisabled, trigger, getValues,
}: ICustomerSiteInfo) {
  const [open, setOpen] = useState(false);
  const { jobId } = useParams();

  const { phoneCodes, countries } = useSelector((state: RootState) => state.masterDataReducer);
  const { selectedSite, siteTypes } = useSelector((state: RootState) => state.jobStarterReducer);

  const handleAction = () => {
    if (isDisabled) {
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedSite?.SiteId) {
      assignSiteValues(setValue, selectedSite);
      trigger(CustomerSiteFormFields.SiteId);
    }
  }, [selectedSite]);

  const handleOnChange = (text: string, onChange: (event: string) => void, value: string) => {
    if (value?.length > 0) {
      return;
    }
    onChange('');
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Site Information
        </div>
        <Controller
          control={control}
          name={CustomerSiteFormFields.SiteId}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <TextField
              size="small"
              value={value}
              disabled={Boolean(jobId)}
              // onClick={handleAction}
              onClick={jobId ? () => {} : handleAction}
              onChange={(text) => handleOnChange(text.currentTarget.value, onChange, value)}
              id={CustomerSiteFormFields.SiteId}
              placeholder="Site ID *"
              error={!!errors.siteId}
              helperText={errors?.siteId?.message}
              InputProps={{
                endAdornment: (
                  <Tooltip title="Search here..">
                    <IconButton
                      onClick={handleAction}
                      disabled={Boolean(jobId)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                ),
              }}
              className="mob-wd-100"
            />
          )}
        />
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Site</label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                name={CustomerSiteFormFields.SiteName}
                id={CustomerSiteFormFields.SiteName}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <ComponentTextInput
                // isDisable
                control={control}
                inputProps={{ maxLength: 30 }}
                name={CustomerSiteFormFields.SiteContact}
                id={CustomerSiteFormFields.SiteContact}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address</label>
              <ComponentTextInput
                control={control}
                className="nonCapitalizeField"
                name={CustomerSiteFormFields.SiteAddress}
                id={CustomerSiteFormFields.SiteAddress}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address (Optional)</label>
              <ComponentTextInput
                isDisable
                control={control}
                className="nonCapitalizeField"
                name={CustomerSiteFormFields.SiteOptionalAddress}
                id={CustomerSiteFormFields.SiteOptionalAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Country</label>
              <ComponentSelectInput
                name={CustomerSiteFormFields.SiteCountry}
                control={control}
                defaultValue=""
                disabled
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Zip</label>
              <ComponentTextInput
                control={control}
                name={CustomerSiteFormFields.SiteZip}
                regEx={extRegex}
                isDisable
                id={CustomerSiteFormFields.SiteZip}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">State</label>
              <ComponentTextInput
                control={control}
                name={CustomerSiteFormFields.SiteState}
                id={CustomerSiteFormFields.SiteState}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">City</label>
              <ComponentTextInput
                control={control}
                name={CustomerSiteFormFields.SiteCity}
                id={CustomerSiteFormFields.SiteCity}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <ComponentSelectInput
                name={CustomerSiteFormFields.SitePhoneCode}
                control={control}
                // disabled
                defaultValue=""
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <ComponentTextInput
                control={control}
                name={CustomerSiteFormFields.SitePhone}
                id={CustomerSiteFormFields.SitePhone}
                // isDisable
                regEx={phoneRegex}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <ComponentTextInput
                control={control}
                // isDisable
                name={CustomerSiteFormFields.SiteCell}
                id={CustomerSiteFormFields.SiteCell}
                regEx={phoneRegex}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Site Type</label>
              <ComponentSelectInput
                name={CustomerSiteFormFields.SiteType}
                disabled
                control={control}
                defaultValue=""
                size="small"
                entries={siteTypes}
              />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">SMC</label>
              <ComponentTextInput
                isDisable
                control={control}
                name={CustomerSiteFormFields.SiteSMC}
                id={CustomerSiteFormFields.SiteSMC}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Note</label>
              <Controller
                control={control}
                name={CustomerSiteFormFields.SiteNote}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    inputProps={{ maxLength: 2056 }}
                    disabled
                    value={value}
                    multiline
                    rows={5}
                    maxRows={5}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <AddSiteModal getValues={getValues} show={open} setShow={() => setOpen(false)} />
    </Card>
  );
}
