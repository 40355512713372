import React from 'react';
import {
  Card, FormHelperText, Grid, TextField,
} from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EditorState } from 'draft-js';
import RequiredFiled from '../../../../../utils/RequiredField';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import { RequestFormFields } from '../../utils/constants';
import { setAdminNote, setComment } from '../../redux/equipmentRequestSlice';
import { ComponentRequestMiddleFormInterface } from '../../utils/types';
import { RootState } from '../../../../../redux/rootState';
import { customOptions } from '../../../../../Common/TextEditorOptions';
import { roundTripMilesRegex, vesselRegexWith3D } from '../../../../../utils/regex';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';

export default function ComponentRequestMiddleForm({
  control, errors, watch,
}: ComponentRequestMiddleFormInterface): JSX.Element {
  const { requestId, roleValue } = useParams();
  const isAdmin = requestId && !roleValue;

  const dispatch = useDispatch();
  const unitValue = watch(RequestFormFields.units);

  const {
    inventoryEquipmentMasterData, comment,
  } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const handleComment = (newEditorState: EditorState) => {
    dispatch(setComment(newEditorState));
  };
  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Vessel Information
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">
                Vessel
                <RequiredFiled />
              </label>
              <ComponentSelectInput
                name={RequestFormFields.vessel}
                control={control}
                size="small"
                required
                defaultValue=""
                disabled={isAdmin ? false : !(inventoryEquipmentMasterData?.Vessel?.length > 0) || !!requestId}
                entries={inventoryEquipmentMasterData?.Vessel?.length > 0 ? inventoryEquipmentMasterData?.Vessel : []}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">
                Tube Type
                <RequiredFiled />
              </label>
              <ComponentSelectInput
                name={RequestFormFields.tubeType}
                control={control}
                required
                disabled={!(inventoryEquipmentMasterData?.Tube?.length > 0)}
                size="small"
                defaultValue=""
                entries={inventoryEquipmentMasterData?.Tube?.length > 0 ? inventoryEquipmentMasterData?.Tube : []}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Work Hrs</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                inputProps={{ maxLength: 3 }}
                regEx={/^(?!.*\..*\.)[0-9]*(?:\.[0-9]*)?$/}
                name={RequestFormFields.workHrs}
                id={RequestFormFields.workHrs}
                size="small"
                isDisable={isAdmin ? false : !!requestId}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">
                Material
                <RequiredFiled />
              </label>
              <ComponentSelectInput
                name={RequestFormFields.material}
                defaultValue=""
                required
                disabled={!(inventoryEquipmentMasterData?.Material?.length > 0)}
                control={control}
                size="small"
                entries={inventoryEquipmentMasterData?.Material?.length > 0 ? inventoryEquipmentMasterData?.Material : []}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">OD</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                regEx={roundTripMilesRegex}
                name={RequestFormFields.OD}
                id={RequestFormFields.OD}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">ID</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                regEx={roundTripMilesRegex}
                name={RequestFormFields.ID}
                id={RequestFormFields.ID}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Retest of</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                inputProps={{ maxLength: 30 }}
                name={RequestFormFields.retestOf}
                id={RequestFormFields.retestOf}
                size="small"
                isDisable={isAdmin ? false : !!requestId}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_disabled">Tube Length</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                isDisable
                name={RequestFormFields.tubeLength}
                id={RequestFormFields.tubeLength}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_disabled">Tube Count</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                isDisable
                name={RequestFormFields.tubeCount}
                id={RequestFormFields.tubeCount}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={8} sm={12} xs={12} />
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">NWT</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                regEx={vesselRegexWith3D}
                // inputProps={{ maxLength: 30 }}
                name={RequestFormFields.NWT}
                id={RequestFormFields.NWT}
                size="small"
              />
            </div>
            {!unitValue
              && <FormHelperText id="outlined-weight-helper-text">In inches</FormHelperText>}
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">NWT / Bell / Land</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                regEx={vesselRegexWith3D}
                // inputProps={{ maxLength: 30 }}
                name={RequestFormFields.NWTBellLand}
                id={RequestFormFields.NWTBellLand}
                size="small"
              />
            </div>
            {!unitValue
              && <FormHelperText id="outlined-weight-helper-text">In inches</FormHelperText>}
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">NWT Under Fins</label>
              <ComponentTextInput
                control={control}
                errors={errors}
                regEx={vesselRegexWith3D}
                // inputProps={{ maxLength: 30 }}
                name={RequestFormFields.NWTUnderFins}
                id={RequestFormFields.NWTUnderFins}
                size="small"
              />
            </div>
            {!unitValue
              && <FormHelperText id="outlined-weight-helper-text">In inches</FormHelperText>}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            {/* <div className="div_label_text">
              <label className="label_Style_job">
                Units
              </label>
              <ComponentSelectInput
                name={RequestFormFields.units}
                control={control}
                size="small"
                entries={materials}
              />
            </div> */}
            <div style={{ paddingTop: '10px' }}>
              <label>&nbsp;</label>
              <CustomCheckbox
                name={RequestFormFields.units}
                control={control}
                defaultValue={false}
                label="Gauge to Inches"
              />
            </div>
          </Grid>
          {requestId && roleValue
            && (
              <Grid item md={12} sm={12} xs={12}>
                <Card className="comment-card">
                  <div className="customerInfo_container pl-4">
                    <div className="customerInfo">
                      Notes
                    </div>
                  </div>
                  <div className="customerInfo_main_container">
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12} className="p-0">
                        <div>
                          <Editor
                            editorState={comment}
                            onEditorStateChange={handleComment}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            localization={{ locale: 'en' }}
                            toolbar={customOptions}
                            readOnly
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            )}
          <Grid item md={12} sm={12} xs={12}>
            <div className="customerInfo_container pl-4">
              <div className="customerInfo">
                Admin Notes
                <RequiredFiled />
              </div>
            </div>
            <Controller
              control={control}
              name={RequestFormFields.note}
              render={({ field: { value, onChange } }) => (
                <TextField
                  fullWidth
                  required
                  onChange={(e) => {
                    onChange(e);
                    dispatch(setAdminNote(e.target.value));
                  }}
                  value={value}
                  inputProps={{ maxLength: 255 }}
                  multiline
                  rows={3}
                  maxRows={3}
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
