import * as yup from 'yup';
import { NumericWithOptionalDecimalRegex } from '../../../../utils/regex';
import { RequestForm } from './types';

export const MainDefaultValue = {
  vertical: false,
  safetyEquipmentRequired: false,
  outside: false,
  flux: false,
  absDiff: false,
  crsDiff: false,
  jobId: '',
  manufacturer: '',
  serialId: '',
  model: '',
  vessel: '',
  tubeType: '',
  material: '',
  OD: '',
  ID: '',
  tubeLength: '',
  tubeCount: '',
  NWT: '',
  NWTBellLand: '',
  NWTUnderFins: '',
  note: '',
  workHrs: '',
  retestOf: '',
};

export const validationSchema = yup.object().shape({
  [RequestForm.NWT]: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-nwt',
      'NWT must be either a number up to 5 digits with an optional decimal or an alphanumeric string of up to 5 characters',
      (value) => !value || NumericWithOptionalDecimalRegex.test(value), // Only validate if value exists
    ),
  [RequestForm.NWTBellLand]: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-nwt',
      'NWT/Bell/Land must be either a number up to 5 digits with an optional decimal or an alphanumeric string of up to 5 characters',
      (value) => !value || NumericWithOptionalDecimalRegex.test(value), // Only validate if value exists
    ),
  [RequestForm.NWTUnderFins]: yup
    .string()
    .nullable()
    .notRequired()
    .test(
      'is-valid-nwt',
      'NWT Under Fins must be either a number up to 5 digits with an optional decimal or an alphanumeric string of up to 5 characters',
      (value) => !value || NumericWithOptionalDecimalRegex.test(value), // Only validate if value exists
    ),
  // .max(5, 'NWT must not exceed 5 characters in length'),
});
