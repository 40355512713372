import {
  Button, Card, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AddPlaylist } from '../../../../assets/TableIcons';
import { VesselData } from '../../../Job/ScreenUnitInformation/utils/type';
import { RootState } from '../../../../redux/rootState';
import styles from '../../utils/style.module.scss';
import { VesselDataJobOrder, VesselFormValuesJobOrder } from '../../utils/types';
import { assignVesselData, formatvesselData } from '../../../Job/ScreenUnitInformation/utils/helper';
import {
  setDisplayVessels, setDisplayVesselsAfter, setDropDownVessels, setNumberOfTubes, setTubeLength,
} from '../../../Job/ScreenUnitInformation/redux/unitInfoSlice';
import { tubeLengthRegex } from '../../../../utils/regex';
import { ANALYST_NOTE_TAB, CLIENT_SITE_TAB, VesselFormJobOrder } from '../../utils/constants';
import { getUnitPriceJobOrderStart, setSelectedTab } from '../../redux/sliceJobOrder';
import { MasterData } from '../../../../utils/type';

function ScreenVessels() {
  const {
    vesselMaster, numberOfTubes, tubeLength, machineType,
    displayVessels, displayVesselsAfter, dropDownVessels, selectedUnit,
  } = useSelector((state: RootState) => state.unitInfoReducer);
  const {
    VesselsInfoData, UnitId, mainFormData,
  } = useSelector((state: RootState) => state?.jobOrderReducer);
  const {
    handleSubmit, setValue,
  } = useForm<VesselFormValuesJobOrder>();

  const dispatch = useDispatch();
  const [selectedVessel, setSelectedVessel] = useState<VesselDataJobOrder | null>(null);
  const [manualVessel, setManualVessel] = useState('');
  const [newVesselData, setNewVesselData] = useState<VesselData[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (displayVesselsAfter.length >= displayVessels.length) {
      setNewVesselData(displayVesselsAfter);
    } else {
      setNewVesselData(displayVessels);
    }
  }, [displayVessels]);

  useEffect(() => {
    if (vesselMaster.length > 0 && newVesselData.length === 0 && !selectedUnit?.UnitId) {
      const val = formatvesselData(vesselMaster);
      const displayData: VesselData[] = [];
      const dropDownData: VesselData[] = [];

      val.forEach((vessel: VesselData) => {
        if (vessel.vesselType.toLowerCase() === 'other') {
          return;
        }
        if (vessel.display) {
          displayData.push(vessel);
        } else {
          dropDownData.push(vessel);
        }
      });
      dispatch(setDisplayVessels(displayData));
      dispatch(setDropDownVessels(dropDownData));
    }
  }, [vesselMaster]);

  useEffect(() => {
    if (UnitId && VesselsInfoData.VesselIds.length > 0) {
      const val = assignVesselData(VesselsInfoData?.VesselIds, vesselMaster);
      const displayData: VesselData[] = [];
      const dropDownData: VesselData[] = [];

      val.forEach((vessel: VesselData) => {
        if (vessel.display) {
          displayData.push(vessel);
        } else if (vessel.vesselType.toLowerCase() !== 'other') {
          dropDownData.push(vessel);
        }
      });
      dispatch(setDisplayVessels(displayData));
      dispatch(setDropDownVessels(dropDownData));
      // Calculate Total Number and Max Tube
      const allVessels = [...VesselsInfoData.VesselIds, ...VesselsInfoData.OtherVessels];
      if (displayVesselsAfter.length >= allVessels.length) {
        const totalNumberOfTubes = displayVesselsAfter?.reduce((sum, vessel) => sum + vessel.numberOfTube, 0);
        dispatch(setNumberOfTubes(totalNumberOfTubes));
      } else {
        const totalNumberOfTubes = allVessels?.reduce((sum, vessel) => sum + vessel.NumberOfTubes, 0);
        dispatch(setNumberOfTubes(totalNumberOfTubes));
      }
      const maxTubeLength = Math.max(...allVessels.map((vessel) => vessel.TubeLength));
      dispatch(setTubeLength(maxTubeLength));
    }
  }, [VesselsInfoData, UnitId]);

  const handleTubeLength = (id: number, value: number) => {
    if (tubeLengthRegex && String(value).trim()?.length > 0 && !tubeLengthRegex.test(String(value))) {
      return;
    }

    const data = JSON.parse(JSON.stringify(newVesselData));
    const index = data.findIndex((val: VesselData) => val.id === id);
    data[index].length = value || 0;

    let length = 0;
    data.forEach((vessel: VesselData) => {
      length = Math.max(vessel.length, length);
    });

    dispatch(setTubeLength(length));
    dispatch(setDisplayVessels(data));
    dispatch(setDisplayVesselsAfter(data));
  };

  const handleTubeNumber = (id: number, value: number) => {
    const regEx = /^[0-9]{1,5}$/;

    if (regEx && String(value).trim()?.length > 0 && !regEx.test(String(value))) {
      return;
    }

    const data = JSON.parse(JSON.stringify(newVesselData));
    const index = data.findIndex((val: VesselData) => val.id === id);

    dispatch(setNumberOfTubes(numberOfTubes - data[index].numberOfTube + value));
    data[index].numberOfTube = value;

    if (value === 0) {
      data[index].length = value;
      handleTubeLength(id, 0);
    }

    dispatch(setDisplayVessels(data));
    dispatch(setDisplayVesselsAfter(data));
  };

  const handleAdd = () => {
    if (selectedVessel?.vesselType.toLowerCase() === 'pivot') {
      const newVessel = {
        id: -1,
        vesselName: manualVessel,
        tubeName: `${manualVessel}Name`,
        tubeLength: `${manualVessel}Length`,
        numberOfTube: 0,
        length: 0,
        active: true,
        display: true,
        vesselType: 'manual',
      };
      dispatch(setDisplayVessels([...newVesselData, newVessel]));
      dispatch(setDisplayVesselsAfter([...displayVesselsAfter, newVessel]));
      setSelectedVessel(null);
      setManualVessel('');
    } else if (selectedVessel) {
      const index = dropDownVessels.findIndex((value: VesselData) => value.id === selectedVessel.id);
      const vessel = dropDownVessels[index];
      const temp = JSON.parse(JSON.stringify(dropDownVessels));
      temp.splice(index, 1);
      dispatch(setDropDownVessels(temp));
      dispatch(setDisplayVessels([...newVesselData, vessel]));
      dispatch(setDisplayVesselsAfter([...newVesselData, vessel]));
      setSelectedVessel(null);
    }
  };

  const onSubmit = () => {
    if (UnitId) {
      const machine = machineType?.find((ele: MasterData) => ele.Value === String(mainFormData?.machineType));
      const payload = {
        TestType: mainFormData?.testType || '0',
        MachineType: machine?.Text || '',
        TotalTubeQty: numberOfTubes || 0,
        TubeLength: tubeLength || 0,
        OD: Number(mainFormData?.odTubes) || 0,
        SpotTest: mainFormData?.spotTest,
        SafetyCharge: mainFormData?.safetyClass,
        SpotTestPercentage: Number(mainFormData?.pct) || 0,
      };
      dispatch(getUnitPriceJobOrderStart(payload));
      dispatch(setSelectedTab(ANALYST_NOTE_TAB));
    }
  };

  useEffect(() => {
    if (VesselsInfoData) {
      setValue(VesselFormJobOrder.estimatedTubeQty, VesselsInfoData?.TubeQtyIsEstimated);
      setValue(VesselFormJobOrder.providedTubeQty, VesselsInfoData?.TubeQtyProvidedByCustomer);
    }
  }, [VesselsInfoData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div className="customerInfo_container">
          <div className="customerInfo">
            Vessels Details
          </div>
        </div>
        <div>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ background: '#e5f6ed' }}>
                <TableRow>
                  <TableCell align="left" style={{ width: '139px' }}>
                    Vessel
                  </TableCell>
                  <TableCell align="left" style={{ width: '265px' }}>
                    # of Tube
                  </TableCell>
                  <TableCell align="left">
                    Tube Length
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  newVesselData.map((vessel) => (
                    <TableRow key={vessel.id}>
                      <TableCell align="left" style={{ background: '#e5f6ed' }}>
                        {vessel.vesselName}
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          name={vessel.tubeName}
                          onChange={(event) => handleTubeNumber(vessel.id, Number(event.target.value))}
                          value={vessel.numberOfTube > 0 ? vessel.numberOfTube : ''}
                          size="small"
                          disabled={vessel.display !== false && vessel.vesselType !== 'manual'}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          size="small"
                          onChange={(event) => handleTubeLength(vessel.id, Number(event.target.value))}
                          name={vessel.tubeLength}
                          value={vessel.length > 0 ? vessel.length : ''}
                          disabled={vessel.numberOfTube === 0 || (vessel.display !== false && vessel.vesselType !== 'manual')}
                          // disabled={vessel.numberOfTube === 0}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                }
                <TableRow style={{ display: 'none' }}>
                  <TableCell align="left" style={{ background: '#e5f6ed' }}>
                    <div className={styles.tablecell_div_style}>
                      {selectedVessel?.vesselType?.toLowerCase() !== 'pivot' ? (
                        <Select
                          size="small"
                          fullWidth
                          placeholder="Vessels"
                          className={`${styles.select_style} vesselSelectField`}
                          value={selectedVessel?.id}
                          onChange={(event) => {
                            const changedVessel = dropDownVessels.find((vData) => vData.id === event.target.value) || null;
                            setSelectedVessel(changedVessel);
                          }}
                        >
                          {dropDownVessels?.map((vessel: VesselData) => (
                            <MenuItem value={vessel.id}>{vessel.vesselName}</MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          name="manualVesselType"
                          autoFocus
                          inputProps={{ maxLength: 50 }}
                          onChange={(e) => setManualVessel(e.target.value)}
                          value={manualVessel}
                          className={`${styles.manualVessel} nonCapitalizeField`}
                          size="small"
                        />
                      )}
                      <button
                        type="button"
                        onClick={handleAdd}
                        style={{ backgroundColor: 'rgb(229, 246, 237)' }}
                        disabled={!selectedVessel || (selectedVessel?.vesselType.toLowerCase() === 'pivot' && manualVessel.length === 0)}
                      >
                        <AddPlaylist />
                      </button>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <TextField size="small" disabled />
                  </TableCell>
                  <TableCell align="left">
                    <TextField size="small" disabled />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Card>
      <Card className={styles.total_tube_card}>
        <TableContainer>
          <Table sx={{ minWidth: 1024 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell align="left" className={styles.total_tubes_style}>
                  Total Tubes
                </TableCell>
                <TableCell align="left" style={{ maxWidth: '76px' }}>
                  <TextField
                    name="totalTube"
                    disabled
                    // onChange={(event) => handleTubeNumber(vessel.id, Number(event.target.value))}
                    value={numberOfTubes}
                    size="small"
                  />
                </TableCell>
                <TableCell align="right">
                  <div className={styles.total_tube_test}>
                    <span className={styles.tube_text}>Total Tubes Tested</span>
                    <TextField
                      size="small"
                      disabled
                      inputProps={{ maxLength: 8 }}
                      // onChange={(event) => handleTubeLength(vessel.id, Number(event.target.value))}
                      name="totalTubeTest"
                    // value={vessel.length > 0 ? vessel.length : ''}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card className={styles.vessel_button_style}>
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => dispatch(setSelectedTab(CLIENT_SITE_TAB))}>Back</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next" disabled={numberOfTubes === 0}>Save & Next</Button>
          </div>
        </div>
      </Card>
    </form>
  );
}

export default ScreenVessels;
