import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Cancel } from '../../media/images/Cancel';
import { LocalizationContext } from '../Localization/LocalizationProvider';
import './Style.scss';
// eslint-disable-next-line import/no-cycle
import DataTable from '../DataTable';
import { TypesCustomerSearchModal } from './Type';
import { getCustomersStart } from '../../Screens/customer/redux/customerSlice';
import { RootState } from '../../redux/rootState';
import { CustomerData } from './utils/TypeCustomer';
import { formatTableData } from './utils/helper';
import EndPoints from '../../Routes/EndPoints';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  // height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  height: 'auto',
  '@media (max-width: 768px)': {
    p: '6px 6px 15px',
    height: 'auto',
  },
};
export default function AddCustomerModal({
  show, setOpenFrom, handleTabCell,
}: TypesCustomerSearchModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { customers, totalEntries } = useSelector((state: RootState) => state.customerReducer);
  const [selectedRadio, setSelectedRadio] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };

    dispatch(getCustomersStart(params));
  };

  useEffect(() => {
    if (customers.length === 0) {
      handleData();
    }
    return () => {
      setSelectedRadio('');
    };
  }, []);

  const { translations } = useContext(LocalizationContext);
  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'markUp', label: translations.markup },
      { key: 'cust', label: translations.CustHash },
      { key: 'company', label: translations.Company },
      { key: 'address', label: translations.address },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
      { key: 'phone', label: translations.phone },
    ],
    [],
  );
  const handleButtonClick = () => {
    navigate(EndPoints.ADD_CUSTOMER);
  };

  const handleSave = () => {
    if (handleTabCell) {
      handleTabCell(selectedRadio);
    }
    setOpenFrom();
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">
              {translations.customer}
            </div>
            <div className="cancel-icon" onClick={setOpenFrom}>
              <Cancel />
            </div>
          </div>
          <DataTable<CustomerData>
            handleButtonClick={handleButtonClick}
            data={customers ? formatTableData(customers) : []}
            columns={columns}
            totalRecords={totalEntries}
            buttonText="Add New Customer"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            radioButtonColumns={['select']}
          />
          {
            customers.length > 0 && (
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={setOpenFrom}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    {/* Pass Id Of The Selected Customer In The Handle Change */}
                    <Button className="button_save_and_next" onClick={handleSave}>{translations.select}</Button>
                  </div>
                </div>
              </Grid>
            )
          }
        </Box>
      </Modal>
    </div>
  );
}
