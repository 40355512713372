import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateUnitPayload, GetUnitByIdResponse, GetUnitPriceResponse, UnitInfoFormValues,
  UnitInfoInitialValues, UnitMasterResponse, UnitPricePayload, UpdateUnitPayload, VesselData, VesselFormValues, VesselList,
} from '../utils/type';
import { MasterData } from '../../../../utils/type';

const initialState:UnitInfoInitialValues = {
  selectedTab: 1,
  unitInfo: {} as UnitInfoFormValues,
  isLoading: false,
  unitId: '',
  vesselMaster: [],
  unitMaster: {} as UnitMasterResponse,
  machineType: [] as MasterData[],
  numberOfTubes: 0,
  tubeLength: 0,
  unitPrice: {} as GetUnitPriceResponse,
  selectedUnit: {} as GetUnitByIdResponse,
  displayVessels: [],
  displayVesselsAfter: [],
  dropDownVessels: [],
  vesselInfo: {} as VesselFormValues,
  SerialNo: '',
  isUserInteracting: false,
};

export const unitInfo = createSlice({
  name: 'unitInfo',
  initialState,
  reducers: {
    setSelectedTab(state, action:PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },

    setIsUserInteracting(state, action:PayloadAction<boolean>) {
      return {
        ...state,
        isUserInteracting: action.payload,
      };
    },

    resetUnitInfo() {
      return initialState;
    },

    setDisplayVessels(state, action:PayloadAction<VesselData[]>) {
      return {
        ...state,
        displayVessels: [...action.payload],
      };
    },
    setDisplayVesselsAfter(state, action:PayloadAction<VesselData[]>) {
      return {
        ...state,
        displayVesselsAfter: [...action.payload],
      };
    },
    removeDisplayVesselsAfter(state) {
      return {
        ...state,
        displayVesselsAfter: [],
      };
    },
    setVesselInfo(state, action:PayloadAction<VesselFormValues>) {
      return {
        ...state,
        vesselInfo: action.payload,
      };
    },

    changeMachineTyoe(state, action:PayloadAction<MasterData[]>) {
      return {
        ...state,
        machineType: action.payload,
      };
    },

    setDropDownVessels(state, action:PayloadAction<VesselData[]>) {
      return {
        ...state,
        dropDownVessels: action.payload,
      };
    },

    setUnitInfo(state, action:PayloadAction<UnitInfoFormValues>) {
      return {
        ...state,
        unitInfo: action.payload,
      };
    },
    setNumberOfTubes(state, action:PayloadAction<number>) {
      return {
        ...state,
        numberOfTubes: action.payload,
      };
    },
    setTubeLength(state, action:PayloadAction<number>) {
      return {
        ...state,
        tubeLength: action.payload,
      };
    },

    // Create Unit
    createUnitStart(state, _action:PayloadAction<CreateUnitPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createUnitSuccess(state, action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        unitId: action.payload,
      };
    },
    createUnitFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Update Unit
    updateUnitStart(state, _action:PayloadAction<UpdateUnitPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateUnitSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateUnitFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Vessels Master
    getVesselMasterStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getVesselMasterSuccess(state, action:PayloadAction<VesselList[]>) {
      return {
        ...state,
        vesselMaster: action.payload,
        isLoading: false,
      };
    },
    getVesselMasterFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Unit Master
    getUnitMasterStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getUnitMasterSuccess(state, action:PayloadAction<UnitMasterResponse>) {
      return {
        ...state,
        unitMaster: action.payload,
        machineType: action.payload.Machine,
        isLoading: false,
      };
    },
    getUnitMasterFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Unit Price
    getUnitPriceStart(state, _action:PayloadAction<UnitPricePayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getUnitPriceSuccess(state, action:PayloadAction<GetUnitPriceResponse>) {
      return {
        ...state,
        unitPrice: action.payload,
        isLoading: false,
      };
    },
    getUnitPriceFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Unit By Id
    getUnitByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getUnitByIdSuccess(state, action:PayloadAction<GetUnitByIdResponse>) {
      return {
        ...state,
        isLoading: false,
        selectedUnit: action.payload,
        unitId: action.payload.UnitId,
      };
    },
    getUnitByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Serial # Number
    getSerialNoStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSerialNoSuccess(state, action:PayloadAction<string>) {
      return {
        ...state,
        SerialNo: action.payload,
        isLoading: false,
      };
    },
    getSerialNoFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  setSelectedTab, setUnitInfo, createUnitStart, createUnitSuccess, createUnitFailure,
  getVesselMasterStart, getVesselMasterSuccess, getVesselMasterFailure, getUnitMasterStart,
  getUnitMasterSuccess, getUnitMasterFailure, setNumberOfTubes, setTubeLength,
  getUnitPriceStart, getUnitPriceSuccess, getUnitPriceFailure, getUnitByIdStart,
  getUnitByIdSuccess, getUnitByIdFailure, updateUnitStart, updateUnitFailure, updateUnitSuccess,
  resetUnitInfo, setDisplayVessels, setDisplayVesselsAfter, setDropDownVessels, changeMachineTyoe, setVesselInfo,
  getSerialNoStart, getSerialNoSuccess, getSerialNoFailure, setIsUserInteracting, removeDisplayVesselsAfter,
} = unitInfo.actions;
export const unitInfoReducer = unitInfo.reducer;

export type UnitInfoActions =
| ReturnType<typeof setSelectedTab>
| ReturnType<typeof setUnitInfo>
| ReturnType<typeof createUnitStart>
| ReturnType<typeof createUnitSuccess>
| ReturnType<typeof createUnitFailure>
| ReturnType<typeof getVesselMasterStart>
| ReturnType<typeof getVesselMasterSuccess>
| ReturnType<typeof getVesselMasterFailure>
| ReturnType<typeof getUnitMasterStart>
| ReturnType<typeof getUnitMasterSuccess>
| ReturnType<typeof getUnitMasterFailure>
| ReturnType<typeof setNumberOfTubes>
| ReturnType<typeof setTubeLength>
| ReturnType<typeof getUnitPriceStart>
| ReturnType<typeof getUnitPriceSuccess>
| ReturnType<typeof getUnitPriceFailure>
| ReturnType<typeof getUnitByIdStart>
| ReturnType<typeof getUnitByIdSuccess>
| ReturnType<typeof getUnitByIdFailure>
| ReturnType<typeof updateUnitStart>
| ReturnType<typeof updateUnitSuccess>
| ReturnType<typeof updateUnitFailure>
| ReturnType<typeof resetUnitInfo>
| ReturnType<typeof setDropDownVessels>
| ReturnType<typeof setDisplayVessels>
| ReturnType<typeof setDisplayVesselsAfter>
| ReturnType<typeof changeMachineTyoe>
| ReturnType<typeof setVesselInfo>
| ReturnType<typeof getSerialNoStart>
| ReturnType<typeof getSerialNoSuccess>
| ReturnType<typeof getSerialNoFailure>
| ReturnType<typeof setIsUserInteracting>
| ReturnType<typeof removeDisplayVesselsAfter>;
