import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import './Dashboard.scss';
import DashboardChart from './DashboardChart';
import { RootState } from '../../redux/rootState';
import { getDashboardData, resetDashboardState } from './redux/dashboardSlice';
import { setLoader } from '../../redux/loaderSlice';
import bannerImage from '../../assets/BannerImage.svg';
import userIcon from '../../assets/Icons/userIconImage.svg';

function DashBoard() {
  const dispatch = useDispatch();
  const [oilKitOrder, setOilKitOrder] = useState<number>(0);
  const [freonOrder, setFreonOrder] = useState<number>(0);

  const {
    isLoading, type, fromDate, toDate, dashboardData,
  } = useSelector((state: RootState) => state.dashboardReducer);

  useEffect(() => {
    const reqBody = {
      fromDate,
      toDate,
      type,
    };
    dispatch(getDashboardData(reqBody));
    return () => {
      dispatch(resetDashboardState());
    };
  }, [type, fromDate, toDate]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (dashboardData?.OilKitsOrderCounts) {
      setOilKitOrder(dashboardData?.OilKitsOrderCounts);
    }
    if (dashboardData?.FreonOrderCounts) {
      setFreonOrder(dashboardData?.FreonOrderCounts);
    }
  }, [dashboardData]);

  return (
    <>
      <ComponentPageHeader subHeading="Dashboard" />
      <div className="dashboardCardWrapper div_job_container_info">
        <Grid container spacing={2}>
          <Grid item md={7} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="dashboardBanner"><img src={bannerImage} alt="Banner" /></div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="dashboardCardContainer">
                  <div className="dashboardCardIcon purple">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div className="dashboardCardContent">
                    <div className="dashboardCardText">Number of Customer Onboarded</div>
                    <div className="dashboardCardValue">{dashboardData.CustomerCounts}</div>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="dashboardCardContainer">
                  <div className="dashboardCardIcon orange">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div className="dashboardCardContent">
                    <div className="dashboardCardText">Number of Jobs Created</div>
                    <div className="dashboardCardValue">{dashboardData.JobCounts}</div>
                  </div>
                </div>
              </Grid>
              {/* <Grid item md={6} sm={12} xs={12}>
                <div className="dashboardCardContainer">
                  <div className="dashboardCardIcon green">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div className="dashboardCardContent">
                    <div className="dashboardCardText">Number of Customer Onboarded</div>
                    <div className="dashboardCardValue">80</div>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="dashboardCardContainer">
                  <div className="dashboardCardIcon blue">
                    <img src={userIcon} alt="icon" />
                  </div>
                  <div className="dashboardCardContent">
                    <div className="dashboardCardText">Number of Customer Onboarded</div>
                    <div className="dashboardCardValue">80</div>
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <DashboardChart oilKitOrders={oilKitOrder} freonOrders={freonOrder} />
          </Grid>
        </Grid>
      </div>
      <div className="footer">
        Copyright © 2023 &nbsp;
        <Link to="http://taiservices.com/" target="_blank">Taiservices</Link>
        . Designed by&nbsp;
        <Link to="https://binmile.com/" target="_blank">Binmile Technologies Private Limited</Link>
        .&nbsp;All rights reserved.
      </div>
    </>
  );
}

export default DashBoard;
