import {
  Observable, catchError, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { ofType } from 'redux-observable';
import { AxiosError } from 'axios';
import {
  CalenderActions, createBookMarkFailure, createBookMarkStart, createBookMarkSuccess, createEventFailure, createEventStart, createEventSuccess,
  createPublicHolidayFailure,
  createPublicHolidayStart,
  createPublicHolidaySuccess,
  deleteBookMarkFailure,
  deleteBookMarkStart,
  deleteBookMarkSuccess,
  deleteEventFailure,
  deleteEventStart,
  deleteEventSuccess,
  getAllBookmarksFailure,
  getAllBookmarksStart,
  getAllBookmarksSuccess,
  getAllEventsFailure,
  getAllEventsStart, getAllEventsSuccess, getAllPublicHolidayFailure, getAllPublicHolidayStart,
  getAllPublicHolidaySuccess, getJobInfoFailure, getJobInfoStart, getJobInfoSuccess, getMasterEventsFailure, getMasterEventsStart,
  getMasterEventsSuccess, updateBookMarkFailure, updateBookMarkStart, updateBookMarkSuccess, updateEventFailure, updateEventStart, updateEventSuccess,
} from './calenderSlice';
import Config from '../../../Common/Config';
import { GetById, ListingResponse } from '../../../utils/type';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { Entries } from '../../../Common/ComponentSelectInput/types';
import {
  AddEventPayload, AllEventsList, BookMarks, CreateBookMarkPayload, DeleteBookmarkPayload, DeleteEventPayload,
  GetAllEventsQuery, GetPublicHolidaysResponse, JobEventDetails,
  PublicHolidayPayload, UpdateBookMarkPayload, UpdateEventPayload,
} from '../utils/types';

async function getMasterEvents(): Promise<ListingResponse<Entries>> {
  const url = `${Config.calender.getMasterEvents}`;
  const result = await makeGetRequest<ListingResponse<Entries>>(url);
  return result.data;
}

export const getMasterEventEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(getMasterEventsStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getMasterEvents()).pipe(
    map((res: ListingResponse<Entries>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getMasterEventsSuccess(res.BMT.Result.Data);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getMasterEventsFailure();
    }),
    takeUntil(action$.pipe(filter(getMasterEventsStart.match))),
    catchError((error) => of(getMasterEventsFailure(error))),
  )),
);

async function createEvent(data: AddEventPayload): Promise<GetById<string>> {
  const url = `${Config.calender.createEvent}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const createEventEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(createEventStart.type),
  map((x) => x.payload),
  mergeMap((data:AddEventPayload) => from(createEvent(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(createEventSuccess(), getAllEventsStart({ month: data.selectedMonth, year: data.selectedYear, week: data.selectedWeek }));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(createEventFailure(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(createEventStart.match))),
    catchError((error: AxiosError<GetById<number>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      showErrorToaster(errorMessage);
      return of(createEventFailure(errorMessage));
    }),
  )),
);

async function getAllEvents(data:GetAllEventsQuery): Promise<GetById<AllEventsList>> {
  // eslint-disable-next-line max-len
  const url = `${Config.calender.getAllEvents}?Month=${data.month ?? ''}&Year=${data.year}&Week=${data?.week ?? ''}&AnalystId=${data.analystId ?? ''}`;
  const result = await makeGetRequest<GetById<AllEventsList>>(url);
  return result.data;
}

export const getAllEventsEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(getAllEventsStart.type),
  map((x) => x.payload),
  mergeMap((data: GetAllEventsQuery) => from(getAllEvents(data)).pipe(
    map((res: GetById<AllEventsList>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAllEventsSuccess(res.BMT.Result.AnalystData);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAllEventsFailure();
    }),
    takeUntil(action$.pipe(filter(getAllEventsStart.match))),
    catchError((error) => of(getAllEventsFailure(error))),
  )),
);

async function updateEvent(data:UpdateEventPayload): Promise<GetById<string>> {
  const url = `${Config.calender.updateEvent}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateEventEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(updateEventStart.type),
  map((x) => x.payload),
  mergeMap((data: UpdateEventPayload) => from(updateEvent(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateEventSuccess(), getAllEventsStart({ month: data.selectedMonth, year: data.selectedYear, week: data.selectedWeek }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateEventFailure());
    }),
    takeUntil(action$.pipe(filter(updateEventStart.match))),
    catchError((error) => of(updateEventFailure(error))),
  )),
);

async function deleteEvent(data:DeleteEventPayload): Promise<GetById<string>> {
  const url = `${Config.calender.deleteEvent}?id=${data.eventId}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}

export const deleteEventEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(deleteEventStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteEventPayload) => from(deleteEvent(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteEventSuccess(), getAllEventsStart({ month: data.selectedMonth, year: data.selectedYear, week: data.selectedWeek }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteEventFailure());
    }),
    takeUntil(action$.pipe(filter(deleteEventStart.match))),
    catchError((error) => of(deleteEventFailure(error))),
  )),
);

async function getAllBookmarks(data:GetAllEventsQuery): Promise<GetById<BookMarks[]>> {
  const url = `${Config.calender.getAllBookmarks}?Year=${data.year}&Month=${data.month}&Week=${data.week}`;
  const result = await makeGetRequest<GetById<BookMarks[]>>(url);
  return result.data;
}

export const getAllBookmarksEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(getAllBookmarksStart.type),
  map((x) => x.payload),
  mergeMap((data: GetAllEventsQuery) => from(getAllBookmarks(data)).pipe(
    map((res: GetById<BookMarks[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAllBookmarksSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAllBookmarksFailure();
    }),
    takeUntil(action$.pipe(filter(getAllBookmarksStart.match))),
    catchError((error) => of(getAllBookmarksFailure(error))),
  )),
);

async function createBookMark(data:CreateBookMarkPayload): Promise<GetById<string>> {
  const url = `${Config.calender.createBookMark}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const createBookMarkEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(createBookMarkStart.type),
  map((x) => x.payload),
  mergeMap((data: CreateBookMarkPayload) => from(createBookMark(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(createBookMarkSuccess(), getAllBookmarksStart({ month: data.month, year: data.year, week: data.week }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(createBookMarkFailure());
    }),
    takeUntil(action$.pipe(filter(createBookMarkStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      showErrorToaster(errorMessage);
      return of(createBookMarkFailure());
    }),
  )),
);

async function updateBookMark(data:UpdateBookMarkPayload): Promise<GetById<string>> {
  const url = `${Config.calender.createBookMark}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateBookMarkEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(updateBookMarkStart.type),
  map((x) => x.payload),
  mergeMap((data: UpdateBookMarkPayload) => from(updateBookMark(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateBookMarkSuccess(), getAllBookmarksStart({ month: data.month, year: data.year, week: data.week }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateBookMarkFailure());
    }),
    takeUntil(action$.pipe(filter(updateBookMarkStart.match))),
    catchError((error) => of(updateBookMarkFailure(error))),
  )),
);

async function deleteBookMark(data:DeleteBookmarkPayload): Promise<GetById<string>> {
  const url = `${Config.calender.deleteBookMark}?id=${data.id}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}

export const deleteBookMarkEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(deleteBookMarkStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteBookmarkPayload) => from(deleteBookMark(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteBookMarkSuccess(), getAllBookmarksStart({ month: data.month, year: data.year, week: data.week }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteBookMarkFailure());
    }),
    takeUntil(action$.pipe(filter(deleteBookMarkStart.match))),
    catchError((error) => of(deleteBookMarkFailure(error))),
  )),
);

async function getJobEventDetails(unitId:string): Promise<GetById<JobEventDetails>> {
  const url = `${Config.calender.getJobEventDetails}?UnitId=${unitId}`;
  const result = await makeGetRequest<GetById<JobEventDetails>>(url);
  return result.data;
}

export const getJobEventDetailsEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(getJobInfoStart.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getJobEventDetails(data)).pipe(
    map((res: GetById<JobEventDetails>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getJobInfoSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getJobInfoFailure();
    }),
    takeUntil(action$.pipe(filter(getJobInfoStart.match))),
    catchError((error) => of(getJobInfoFailure(error))),
  )),
);

async function getPublicHolidays(data:GetAllEventsQuery): Promise<GetById<GetPublicHolidaysResponse[]>> {
  const url = `${Config.calender.getPublicHolidays}?month=${data.month}&year=${data.year}&week=${data.week}`;
  const result = await makeGetRequest<GetById<GetPublicHolidaysResponse[]>>(url);
  return result.data;
}

export const getPublicHolidaysEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(getAllPublicHolidayStart.type),
  map((x) => x.payload),
  mergeMap((data: GetAllEventsQuery) => from(getPublicHolidays(data)).pipe(
    map((res: GetById<GetPublicHolidaysResponse[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAllPublicHolidaySuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAllPublicHolidayFailure();
    }),
    takeUntil(action$.pipe(filter(getAllPublicHolidayStart.match))),
    catchError((error) => of(getAllPublicHolidayFailure(error))),
  )),
);

async function createPublicHoliday(data:PublicHolidayPayload): Promise<GetById<string>> {
  const url = `${Config.calender.createPublicHoliday}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}

export const createPublicHolidayEpic = (action$ : Observable<CalenderActions>) => action$.pipe(
  ofType(createPublicHolidayStart.type),
  map((x) => x.payload),
  mergeMap((data: PublicHolidayPayload) => from(createPublicHoliday(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(createPublicHolidaySuccess(), getAllPublicHolidayStart({ month: data.month, year: data.year, week: data.week }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(createPublicHolidayFailure());
    }),
    takeUntil(action$.pipe(filter(createPublicHolidayStart.match))),
    catchError((error) => of(createPublicHolidayFailure(error))),
  )),
);
