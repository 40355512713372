/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Input, Radio, RadioGroup, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
// import { ContentState, EditorState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import Editor, { ContentEditableEvent } from 'react-simple-wysiwyg';
import { useParams } from 'react-router-dom';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../Common/RequiredField';
import { GetUserDetailsPayload, SendEmailFormValues, TypesSendEmailDialogBox } from '../utils/types';
import {
  radioOptions, SendEmailForm, sendEmailSchema, SendMailDefaultValue,
} from '../utils/helper';
// import { customOptions } from '../../../Common/TextEditorOptions';
import { AttachmentIcon } from '../../../assets/TableIcons/AttachmentIcon';
import { DEFAULT_DESCRIPTION_GMAIL } from '../utils/constants';
import { getUserDetailsListStart, sendEmailStart } from '../redux/sliceReportWorkspace';
import { RootState } from '../../../redux/rootState';
import styles from '../utils/reportingStyle.module.scss';
import { showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentSendEmailModel({
  show, setOpenFrom, emailsData,
}: TypesSendEmailDialogBox): JSX.Element {
  const dispatch = useDispatch();
  const [comment, setComment] = useState<string>(DEFAULT_DESCRIPTION_GMAIL);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [plainText, setPlainText] = useState<string>('');
  const MAX_CHARACTER_LIMIT = 3000;
  const { id } = useParams();

  const { isLoading, UserDetailsData } = useSelector((state: RootState) => state.reportWorkspaceReducer);

  const {
    control, formState: { errors }, handleSubmit, setValue,
  } = useForm<SendEmailFormValues>({
    defaultValues: SendMailDefaultValue as unknown as SendEmailFormValues,
    resolver: yupResolver(sendEmailSchema as ObjectSchema<SendEmailFormValues>),
  });

  const handleClose = () => {
    setSelectedFile(null);
    setOpenFrom();
  };

  const fileInputRef = useRef(null);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const selected = files[0];
      setSelectedFile(selected);
      showSuccessToaster('File attached successfully');
    }
  };

  useEffect(() => {
    setValue(SendEmailForm.to, emailsData);
  }, [emailsData]);

  const handleComment = (event: ContentEditableEvent) => {
    const htmlContent = event.target.value;
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = htmlContent;
    const plainTextContent = tempDivElement.textContent || tempDivElement.innerText || '';

    if (plainTextContent.length <= MAX_CHARACTER_LIMIT) {
      setComment(htmlContent);
      setPlainText(plainTextContent);
    }
  };

  useEffect(() => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = comment;
    const plainTextContent = tempDivElement.textContent || tempDivElement.innerText || '';

    if (plainTextContent.length <= MAX_CHARACTER_LIMIT) {
      setComment(comment);
      setPlainText(plainTextContent);
    }
  }, [comment]);

  const onSubmit = (values: SendEmailFormValues) => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append('File', selectedFile);
    }
    formData.append('RecieverMail', values.to ?? '');
    formData.append('Subject', values.subject ?? '');
    formData.append('Body', comment ?? '');
    formData.append('CcMail', values.cc ?? '');
    dispatch(sendEmailStart(formData));
    handleClose();
  };

  const handleTestTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = radioOptions.find((item) => item.value === e.target.value);
    switch (data?.value) {
      case '2':
        setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData.SerialNumber} - ${data.label}`);
        break;
      case '3':
        setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData.SerialNumber} - ${data.label}`);
        break;
      case '4':
        setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData.SerialNumber} - ${data.label}`);
        break;
      case '5':
        setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData.SerialNumber} - ${data.label}`);
        break;
      default:
        setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData.SerialNumber}`);
        break;
    }
  };

  useEffect(() => {
    setValue(SendEmailForm.subject, `${UserDetailsData?.Location} _ ${UserDetailsData?.SerialNumber}`);
  }, [UserDetailsData.Id]);

  useEffect(() => {
    const payload = {
      jobOrderId: id,
    };
    dispatch(getUserDetailsListStart(payload as GetUserDetailsPayload));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        {
        isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        )
        }
        <DialogTitle className="modalHeaderTitle">
          Subject Comments
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="div_label_text">
            <label className="label_Style_job">&nbsp;</label>
            <FormControl>
              <RadioGroup
                row
                defaultValue="1"
                onChange={(event) => {
                  handleTestTypeChange(event);
                }}
              >
                {radioOptions.map((option) => (
                  <div className="type_of_test" key={option.value}>
                    <FormControlLabel
                      labelPlacement="start"
                      value={option.value}
                      control={<Radio color="success" />}
                      label={<span className="radio_button_style">{option.label}</span>}
                    />
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogTitle>
        <DialogContent className={styles.DialogContentStyle}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    To:
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={SendEmailForm.to}
                    size="small"
                    id={SendEmailForm.to}
                    inputProps={{ maxLength: 250 }}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">CC:</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={SendEmailForm.cc}
                    size="small"
                    id={SendEmailForm.cc}
                    errors={errors}
                    inputProps={{ maxLength: 250 }}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Subject:
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={SendEmailForm.subject}
                    size="small"
                    id={SendEmailForm.subject}
                    errors={errors}
                    inputProps={{ maxLength: 500 }}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Input
                    type="file"
                    inputProps={{ accept: '.pdf', style: { display: 'none' } }}
                    id="fileInputEmail"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="fileInputEmail">
                    <Button className="button_save_and_next" component="span">
                      <AttachmentIcon />
                    </Button>
                  </label>
                </div>
                <div className={styles.pdfFileName}>
                  <label>{selectedFile?.name}</label>
                </div>
                <div className={styles.SendEmailEditor}>
                  <Editor value={comment} onChange={handleComment} tagName="p" />
                </div>
                <div className={styles.CharacterCount}>
                  <span>
                    {plainText?.length}
                    /
                    {MAX_CHARACTER_LIMIT}
                    {' '}
                    Characters
                  </span>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button disabled={selectedFile === null} type="submit" className="button_save_and_next">Send Email</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default ComponentSendEmailModel;
