import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import AddEventModal from './components/addEventModal';
import CalenderGrid from './components/grid';
import AddPublicHolidayModal from './components/addPublicHoliday';
import AddBookmarkModal from './components/addBookmarkModal';
import {
  deleteEventStart,
  removeCalenderState, setSelectedDeleteEventId, setSelectedEvent, setShowBookmarkModals, setShowDeleteModal, setShowEventModal,
} from './redux/calenderSlice';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { DeleteEventPayload } from './utils/types';
import { removeSelectedCustomer } from '../addCustomer/redux/addCustomerSlice';

function Calendar() {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showPublicHolidayModal, setShowPublicHolidayModal] = useState(false);
  // const [showBookmarkModal, setShowBookmarkModal] = useState(false);

  const {
    isLoading, showDeleteModal, selectedDeleteEventId, selectedMonth, selectedYear, selectedWeek, showBookmarkModal,
  } = useSelector((state:RootState) => state.CalenderReducer);

  const dispatch = useDispatch();

  const handleEventModalClose = () => {
    dispatch(setShowEventModal(false));
    dispatch(setSelectedEvent(null));
  };

  useEffect(() => () => {
    dispatch(removeCalenderState());
    dispatch(removeSelectedCustomer());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDeleteModalClose = () => {
    dispatch(setShowDeleteModal(false));
    dispatch(setSelectedDeleteEventId(''));
  };

  const handleDelete = () => {
    const payload:DeleteEventPayload = {
      eventId: selectedDeleteEventId,
      selectedMonth,
      selectedYear,
      selectedWeek,
    };
    dispatch(deleteEventStart(payload));
    dispatch(setSelectedDeleteEventId(''));
    dispatch(setShowDeleteModal(false));
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Calendar" />
      </Grid>

      <CalenderGrid
        setSelectedDate={setSelectedDate}
        setShowPublicHolidayModal={setShowPublicHolidayModal}
        // setShowBookmarkModal={setShowBookmarkModal}
      />
      <AddEventModal
        startDate={selectedDate}
        handleClose={handleEventModalClose}
      />
      <AddPublicHolidayModal
        show={showPublicHolidayModal}
        handleClose={() => setShowPublicHolidayModal(false)}
      />
      <AddBookmarkModal
        show={showBookmarkModal}
        handleClose={() => dispatch(setShowBookmarkModals(false))}
      />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </Grid>
  );
}

export default Calendar;
