import * as Yup from 'yup';

export enum DefectCodeFormValue {
  ActionCheckBox = 'ActionCheckBox',
  Code = 'Code',
  Color = 'Color',
  Category = 'Category',
  SubCategory = 'SubCategory',
  Description = 'Description',
  AdditionalDescription = 'AdditionalDescription',
}

export const addDefectCodeSchema = Yup.object({
  Code: Yup.string().required('Code is required'),
  Color: Yup.string(),
  Category: Yup.string(),
  SubCategory: Yup.string(),
  Description: Yup.string().required('Description is required'),
});

export const ContactDefaultValue = {
  ActionCheckBox: true,
  Code: '',
  Color: '#FF7722',
  Category: '',
  SubCategory: '',
  Description: '',
};
