import * as Yup from 'yup';

export const defectCodeSchema = Yup.object({
  Code: Yup.string().required('Code is required'),
  Color: Yup.string(),
  Category: Yup.string().required('Category is required'),
  SubCategory: Yup.string().required('Sub-Category is required'),
  Description: Yup.string().required('Description is required'),
  AdditionalDescription: Yup.string(),
});

export const DefectCodeValue = {
  ActionCheckBox: false,
  Code: '',
  Color: '#FF7722',
  Category: '',
  SubCategory: '',
  Description: '',
  AdditionalDescription: '',
};
