import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { CustomPopperBookmarkProps } from '../../utils/types';
import ComponentEditBookmark from './componentEditBookmark';
import { setSelectedBookmark } from '../../redux/calenderSlice';

function CustomBookmarkPopper({
  show, handleClose, calenderDay, distanceFromBottom, monthView,
}: CustomPopperBookmarkProps) {
  const dispatch = useDispatch();
  const handleClosePopper = () => {
    handleClose();
    dispatch(setSelectedBookmark(null));
  };

  // const handlePopperPosition = () => {
  //   if (distanceFromBottom <= 200 && (calenderDay >= (monthView ? 5 : 20))) {
  //     return 'customPopperRightAlignedBottom';
  //   } if (distanceFromBottom <= 200 && calenderDay < (monthView ? 5 : 20)) {
  //     return 'customPopperLeftAlignedBottom';
  //   } if (distanceFromBottom > 200 && calenderDay >= (monthView ? 5 : 20)) {
  //     return 'customPopperLeftAligned';
  //   } if (distanceFromBottom > 200 && calenderDay < (monthView ? 5 : 20)) {
  //     return '';
  //   }
  //   return '';
  // };

  const handlePopperBookmarkPosition = () => {
    if (distanceFromBottom <= 200 && (calenderDay >= (monthView ? 5 : 20))) {
      return 'customBookmarkPopperRightAlignedBottom';
    } if (distanceFromBottom <= 200 && calenderDay < (monthView ? 5 : 20)) {
      return 'customBookmarkPopperLeftAlignedBottom';
    } if (distanceFromBottom > 200 && calenderDay >= (monthView ? 5 : 20)) {
      return 'customBookmarkPopperLeftAligned';
    } if (distanceFromBottom > 200 && calenderDay < (monthView ? 5 : 20)) {
      return '';
    }
    return '';
  };

  return (
    <div
      style={{ display: 'inline-block' }}
    >
      {show && (
        <div className={`customBookmarkPopper ${handlePopperBookmarkPosition()}`}>
          <div className="popper-arrow" />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>Bookmark</div>
            <div className="popperCloseButton" onClick={handleClosePopper}><CloseIcon /></div>
          </div>
          <div>
            <ComponentEditBookmark />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomBookmarkPopper;
