import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { makeDeleteRequest, makeGetRequest, makePutRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import {
  ApiResponse, DeleteApiPayload, JobOrderFormDataById, JobOrderQueryPayload, JobOrderResponseFormat, ResultDataById,
  UpdateJobOrderPayloadData,
} from '../utils/types';
import {
  deleteJobOrderFailure, deleteJobOrderStart, deleteJobOrderSuccess, getJobOrderById, getJobOrderData, getJobOrderDataFailure,
  getJobOrderDataSuccess, getJobOrderFailureById, getJobOrderSuccessById, getUnitPriceJobOrderFailure, getUnitPriceJobOrderStart,
  getUnitPriceJobOrderSuccess, JobOrderActions, JobOrderMoveWorkspaceFailure, JobOrderMoveWorkspaceStart, JobOrderMoveWorkspaceSuccess,
  removeJobOrderComments,
  removeJobOrderMainForm,
  setCheckedIds,
  updateJobOrderFailure, updateJobOrderStart, updateJobOrderSuccess,
} from './sliceJobOrder';
import { GetUnitPriceResponse, UnitPricePayload } from '../../Job/ScreenUnitInformation/utils/type';
import { GetById } from '../../../utils/type';
import { removeDisplayVesselsAfter } from '../../Job/ScreenUnitInformation/redux/unitInfoSlice';
import EndPoints from '../../../Routes/EndPoints';

async function getJobOrderListData(data: JobOrderQueryPayload): Promise<JobOrderResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.jobOrder.getJobOrder}?pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<JobOrderResponseFormat>(url);
  return result.data;
}

export const getJobOrderDataEpic = (action$: Observable<JobOrderActions>) => action$.pipe(
  ofType(getJobOrderData.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: JobOrderQueryPayload) => from(getJobOrderListData(data)).pipe(
    map((res: JobOrderResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getJobOrderDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getJobOrderDataFailure();
    }),
    takeUntil(action$.pipe(filter(getJobOrderData.match))),
    catchError((error) => of(getJobOrderDataFailure(error))),
  )),
);

// Delete Job
async function deleteJobOrder(data: DeleteApiPayload): Promise<ApiResponse> {
  const url = `${Config.jobOrder.deleteJobOrder}?JobOrderNumber=${data.id}`;
  const result = await makeDeleteRequest<ApiResponse>(url);
  return result.data;
}

export const deleteJobOrderEpic = (action$ : Observable<JobOrderActions>) => action$.pipe(
  ofType(deleteJobOrderStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteApiPayload) => from(deleteJobOrder(data)).pipe(
    mergeMap((res: ApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
        };
        return of(deleteJobOrderSuccess(), getJobOrderData(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteJobOrderFailure());
    }),
    takeUntil(action$.pipe(filter(deleteJobOrderStart.match))),
    catchError((error) => of(deleteJobOrderFailure(error))),
  )),
);

// Get Job Order by Id
async function getJobOrderDataById(data: { id: string }): Promise<JobOrderFormDataById> {
  const url = `${Config.jobOrder.getJobOrderById}?JobOrderNumber=${data.id}`;
  const result = await makeGetRequest<JobOrderFormDataById>(url);
  return result.data;
}

export const getJobOrderDataByIdEpic = (action$: Observable<JobOrderActions>) => action$.pipe(
  ofType(getJobOrderById.type),
  map((x) => x.payload),
  mergeMap((data: { id: string }) => from(getJobOrderDataById(data)).pipe(
    map((res: JobOrderFormDataById) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getJobOrderSuccessById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getJobOrderFailureById();
    }),
    takeUntil(action$.pipe(filter(getJobOrderById.match))),
    catchError((error) => of(getJobOrderFailureById(error))),
  )),
);

async function getUnitPriceJobOrder(data:UnitPricePayload): Promise<GetById<GetUnitPriceResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.units.unitPrice}?TestType=${data.TestType}&MachineType=${data.MachineType}&TotalTubeQty=${data.TotalTubeQty}&TubeLength=${data.TubeLength}&OD=${data.OD}&SpotTest=${data.SpotTest}&SafetyCharge=${data.SafetyCharge}&SpotTestPercentage=${data.SpotTestPercentage}`;
  const result = await makeGetRequest<GetById<GetUnitPriceResponse>>(url);
  return result.data;
}

export const getUnitPriceJobOrderEpic = (action$ : Observable<JobOrderActions>) => action$.pipe(
  ofType(getUnitPriceJobOrderStart.type),
  map((x) => x.payload),
  mergeMap((data:UnitPricePayload) => from(getUnitPriceJobOrder(data)).pipe(
    map((res: GetById<GetUnitPriceResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitPriceJobOrderSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUnitPriceJobOrderFailure();
    }),
    takeUntil(action$.pipe(filter(getUnitPriceJobOrderStart.match))),
    catchError((error) => of(getUnitPriceJobOrderFailure(error))),
  )),
);

async function updateJobOrder(data: ResultDataById): Promise<GetById<string>> {
  const url = `${Config.jobOrder.UpdateJobOrder}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}
export const updateJobOrderEpic = (action$ : Observable<JobOrderActions>) => action$.pipe(
  ofType(updateJobOrderStart.type),
  map((x) => x.payload),
  mergeMap((data:UpdateJobOrderPayloadData) => from(updateJobOrder(data.payload)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        data?.navigate(`/${EndPoints.JOB_ORDER}`);
        return of(updateJobOrderSuccess(), removeJobOrderComments(), removeDisplayVesselsAfter(), removeJobOrderMainForm());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateJobOrderFailure());
    }),
    takeUntil(action$.pipe(filter(updateJobOrderStart.match))),
    catchError((error) => of(updateJobOrderFailure(error))),
  )),
);

async function MoveJobOrderToWorkSpace(data: string[]): Promise<GetById<string>> {
  const url = `${Config.jobOrder.JobOrderToWorkspace}?jobOrder=${data}`;
  const result = await makeGetRequest<GetById<string>>(url);
  return result.data;
}
export const JobOrderMoveWorkspaceEpic = (action$ : Observable<JobOrderActions>) => action$.pipe(
  ofType(JobOrderMoveWorkspaceStart.type),
  map((x) => x.payload),
  mergeMap((data:string[]) => from(MoveJobOrderToWorkSpace(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(JobOrderMoveWorkspaceSuccess(), setCheckedIds([]));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(JobOrderMoveWorkspaceFailure());
    }),
    takeUntil(action$.pipe(filter(JobOrderMoveWorkspaceStart.match))),
    catchError((error) => of(JobOrderMoveWorkspaceFailure(error))),
  )),
);
