import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Grid, MenuItem, Paper, Select,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { htmlToText } from 'html-to-text';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import {
  GetRecomByIdPayload, GetStartupTextListRes,
  SetRecomTextPayload,
  UpdateRecomPayload,
} from '../../utils/types';
import { FollowForm } from '../../utils/constants';
import EndPoints from '../../../../Routes/EndPoints';
import {
  getFollowUpTextStart, getInspectionIndicationByIdStart, getRecommendationByIdStart, getStartupTextStart, setRecomText,
  updateRecomStart,
} from '../../redux/recommendationSlice';
import { RootState } from '../../../../redux/rootState';
import { onGetCodeMasterData } from '../../../../redux/masters/masterSlice';
import AddStartupTextModal from '../../../../Common/AddStartText';
import { TypeReportTypeDetails } from '../../../../utils/type';
import RecomDetails from './recomDetails';
import { setLoader } from '../../../../redux/loaderSlice';

function ComponentInspection() {
  const Navigate = useNavigate();
  const { recommendationId } = useParams();
  const dispatch = useDispatch();
  const {
    startUpTextList, recomText, isLoading,
  } = useSelector((state:RootState) => state.recommendationReducer);
  const { codeList } = useSelector((state:RootState) => state.masterDataReducer);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const payload:GetRecomByIdPayload = {
      recomId: String(recommendationId),
      navigate: Navigate,
    };
    dispatch(getRecommendationByIdStart(payload));
    dispatch(getInspectionIndicationByIdStart(payload));
    dispatch(onGetCodeMasterData());
    dispatch(getStartupTextStart());
  }, []);

  const handleTabCell = (id:string) => {
    const entry = startUpTextList.find((ele:GetStartupTextListRes) => ele.Value === id);
    const payload:SetRecomTextPayload = {
      value: htmlToText(entry?.Description || ''),
      replaceText: false,
      preAdd: true,
    };
    dispatch(setRecomText(payload));
  };

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  const handleStartClick = () => {
    setShowModal(true);
  };

  const handleFollowUpClick = () => {
    dispatch(getFollowUpTextStart(String(recommendationId)));
  };

  const handleCodeChange = (value: string) => {
    const entry = codeList?.find((code: TypeReportTypeDetails) => code.Value === value);

    if (entry) {
      const payload:SetRecomTextPayload = {
        value: entry.Description,
        replaceText: false,
        preAdd: false,
      };
      dispatch(setRecomText(payload));
    }
  };

  const handleSave = () => {
    const payload:UpdateRecomPayload = {
      RecommendationText: recomText,
      JobOrder: String(recommendationId),
    };

    dispatch(updateRecomStart(payload));
  };

  const stripHtmlTags = (inputValue: string) => inputValue.replace(/<[^>]*>?/gm, '');

  return (
    <>
      <ComponentPageHeader subHeading="Recommendations" />
      <div style={{ backgroundColor: 'white', padding: '14px 20px', borderTop: '1px solid #E9EBEC' }}>
        <div className="save_and_next_div small_mob_button_container">
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next" onClick={handleFollowUpClick}>Add Follow Up Recomm.</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next" onClick={handleStartClick}>Add Start Text</Button>
          </div>
        </div>
      </div>
      <div className="div_job_container_info customRecommendationScroll">
        <Paper elevation={0}>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Recommendations
            </div>
            <div className="customer_info_checkbox dropdown_side_label">
              <div className="double_checkbox">
                <span className="customerID">
                  Job Order #
                </span>
                <div className="job_input_div">
                  <div className="job_input_style">{recommendationId}</div>
                </div>
              </div>
              <div className="dropdown_container p-relative mob_pt_0">
                <div className="dropdown_side_label">
                  Codes
                </div>
                <div className="selectListStyle p-relative">
                  {/* <ComponentSelectInput
                    className="markup_dropdown"
                    name={FollowForm.codes}
                    control={control}
                    errors={errors}
                    size="small"
                    entries={markups}
                  /> */}
                  <Select
                    className="markup_dropdown"
                    name={FollowForm.codes}
                    size="small"
                    onChange={(e) => handleCodeChange(String(e.target.value))}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '220px',
                          maxWidth: '220px',
                        },
                      },
                    }}
                  >
                    {codeList.length > 0 && codeList?.map((item: TypeReportTypeDetails) => (
                      <MenuItem style={{ display: 'flex', alignItems: 'center' }} className="menu-item-enter" value={item?.Value} key={item?.Value}>
                        {stripHtmlTags(item?.Text)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <RecomDetails />
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={() => Navigate(`/${EndPoints.RECOMMENDATIONS}`)}>Cancel</Button>
            </div>
            <div className="button_margin_left">
              <Button type="submit" onClick={handleSave} className="button_save_and_next">Save</Button>
            </div>
          </div>
        </Grid>
      </div>
      <AddStartupTextModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={startUpTextList}
        handleTabCell={handleTabCell}
      />
    </>
  );
}

export default ComponentInspection;
