/* eslint-disable max-len */
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import FirstPageSection from './firstPageSection';
import TableContentSection from './tableContentSection';
import VesselBayLengthInfoSection from './vesselBayLengthInfoSection';
import VesselInfoSection from './VesselInfoSection';
import SummaryInspectionSection from './summaryInspectionSection';
import RecommendationSection from './recommendationSection';
import DataSheetSection from './dataSheetSection';
import CalibrationProcedureSection from './calibrationProcedureSection';
import AbbreviationsSection from './abbreviationsSection';
import BartChartNewSection from './BartChartNewSection';
import LineChartSection from './LineChartSection';
import TubeBundleLayoutSection from './TubeBundleLayoutSection';
import { RootState } from '../../../redux/rootState';
import StripChartSection from './StripChartSection';
import ExpenseReport from './ComponentsExpenseReport';
import HazardAnalysis from './ComponentHazardAnalysisReport';
import { DocumentParts } from '../constant';
import ComponentPhotos from './ComponentPhotos';
import InspectionProcedure from './inspectionSummary';
import ComponentJobOrder from './ComponentJobOrder/ComponentJobOrder';

const ComponentToPrint = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    checkedSections, reportPdfResponse3, reportPdfResponse2, reportPdfResponse4,
  } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const checkArray = (num: number) => {
    if (checkedSections.some((value) => Number(value) > num)) {
      return true;
    }
    return false;
  };

  const ShowTableContent = (num: number) => {
    if (checkedSections.some((value) => Number(value) >= num)) {
      return true;
    }
    return false;
  };

  const shouldAddPageBreakJobOrder = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      DocumentParts.Vessel_Information,
      DocumentParts.Defect_Graph,
      DocumentParts.Summary_of_Inspection,
      DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Data_Sheets)) {
        requiredSectionsForPageBreak.push(DocumentParts.Data_Sheets);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const jobOrderIndex = checkedSectionss.indexOf(DocumentParts.Job_Order);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Tube_Charts is immediately after JobOrder
    if (jobOrderIndex !== -1 && tubeChartsIndex === jobOrderIndex + 1) {
      return false;
    }

    // Case 2: Only JobOrder is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Job_Order)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if JobOrder is selected with other sections
    return true;
  };

  const shouldAddPageBreakVesselInformation = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling for Vessel_Information
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Vessel_Information,
      DocumentParts.Defect_Graph,
      DocumentParts.Summary_of_Inspection,
      DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Data_Sheets)) {
        requiredSectionsForPageBreak.push(DocumentParts.Data_Sheets);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const vesselInfoIndex = checkedSectionss.indexOf(DocumentParts.Vessel_Information);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Vessel_Bay_Length is immediately after Vessel_Information
    if (vesselInfoIndex !== -1 && tubeChartsIndex === vesselInfoIndex + 1) {
      return false;
    }

    // Case 2: Only Vessel_Information is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Vessel_Information)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if Vessel_Information is selected with other sections
    return true;
  };

  const shouldAddPageBreakDefectGraph = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling for Vessel_Information
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Vessel_Information,
      // DocumentParts.Defect_Graph,
      DocumentParts.Summary_of_Inspection,
      DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Data_Sheets)) {
        requiredSectionsForPageBreak.push(DocumentParts.Data_Sheets);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const defectGraphIndex = checkedSectionss.indexOf(DocumentParts.Defect_Graph);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Vessel_Bay_Length is immediately after.Defect_Graph
    if (defectGraphIndex !== -1 && tubeChartsIndex === defectGraphIndex + 1) {
      return false;
    }

    // Case 2: Only.Defect_Graph is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Defect_Graph)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if.Defect_Graph is selected with other sections
    return true;
  };

  const shouldAddPageBreakSummary = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling for Vessel_Information
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Vessel_Information,
      // DocumentParts.Defect_Graph,
      // DocumentParts.Summary_of_Inspection,
      DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Data_Sheets)) {
        requiredSectionsForPageBreak.push(DocumentParts.Data_Sheets);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const summaryIndex = checkedSectionss.indexOf(DocumentParts.Summary_of_Inspection);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Vessel_Bay_Length is immediately after.Summary_of_Inspection
    if (summaryIndex !== -1 && tubeChartsIndex === summaryIndex + 1) {
      return false;
    }

    // Case 2: Only.Summary_of_Inspection is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Summary_of_Inspection)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if.Summary_of_Inspection is selected with other sections
    return true;
  };

  const shouldAddPageBreakRecommendations = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling for Vessel_Information
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Vessel_Information,
      // DocumentParts.Defect_Graph,
      // DocumentParts.Summary_of_Inspection,
      // DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Data_Sheets)) {
        requiredSectionsForPageBreak.push(DocumentParts.Data_Sheets);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const recommendationsIndex = checkedSectionss.indexOf(DocumentParts.Recommendations);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Vessel_Bay_Length is immediately after.Recommendations
    if (recommendationsIndex !== -1 && tubeChartsIndex === recommendationsIndex + 1) {
      return false;
    }

    // Case 2: Only.Recommendations is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Recommendations)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if.Recommendations is selected with other sections
    return true;
  };

  const shouldAddPageBreakDataSheet = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling for Vessel_Information
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Vessel_Information,
      // DocumentParts.Defect_Graph,
      // DocumentParts.Summary_of_Inspection,
      // DocumentParts.Recommendations,
      // DocumentParts.Data_Sheets,
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
      DocumentParts.Inspection_Procedure,
      // DocumentParts.Hazard_Analysis,
      // DocumentParts.Expense_Report,
    ];

    if (reportPdfResponse3?.Photos?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Photos)) {
        requiredSectionsForPageBreak.push(DocumentParts.Photos);
      }
    }

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    const dataSheetIndex = checkedSectionss.indexOf(DocumentParts.Data_Sheets);
    const tubeChartsIndex = checkedSectionss.indexOf(DocumentParts.Tube_Charts);

    // Case 1: Vessel_Bay_Length is immediately after.Data_Sheets
    if (dataSheetIndex !== -1 && tubeChartsIndex === dataSheetIndex + 1) {
      return false;
    }

    // Case 2: Only.Data_Sheets is selected
    if (checkedSectionss.length === 1 && checkedSectionss.includes(DocumentParts.Data_Sheets)) {
      return false;
    }

    // Case 3: Ensure at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if.Data_Sheets is selected with other sections
    return true;
  };

  const shouldAddPageBreakInspectionProcedure = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Explanation_Of_Abbreviations,
    ];

    // Check if ExpenseReports data exists and dynamically include it

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    // Ensure that at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if all conditions are met
    return true;
  };

  const shouldAddPageBreakCalibrationProcedure = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Inspection_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
    ];

    // Check if ExpenseReports data exists and dynamically include it

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse4?.Inspection_Procedure !== null) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Inspection_Procedure)) {
        requiredSectionsForPageBreak.push(DocumentParts.Inspection_Procedure);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    // Ensure that at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if all conditions are met
    return true;
  };

  const shouldAddPageBreakPhaseChart = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      // DocumentParts.Calibration_Procedure,
      DocumentParts.Inspection_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
    ];

    // Check if ExpenseReports data exists and dynamically include it

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    if (reportPdfResponse4?.Caliberation_Procedure !== null) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Calibration_Procedure)) {
        requiredSectionsForPageBreak.push(DocumentParts.Calibration_Procedure);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    // Ensure that at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if all conditions are met
    return true;
  };

  const shouldAddPageBreakStripChart = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Inspection_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
    ];

    // Check if ExpenseReports data exists and dynamically include it

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    // Ensure that at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if all conditions are met
    return true;
  };

  const shouldAddPageBreakPhotos = (section: DocumentParts, checkedSectionss: string[]) => {
    // Define sections that require special handling
    const requiredSectionsForPageBreak: string[] = [
      DocumentParts.Strip_Charts,
      DocumentParts.Phase_Charts,
      DocumentParts.Calibration_Procedure,
      DocumentParts.Inspection_Procedure,
      DocumentParts.Explanation_Of_Abbreviations,
    ];

    // Check if ExpenseReports data exists and dynamically include it

    if (reportPdfResponse4?.Expense_Report?.some((report) => report.ExpenseReports?.length > 0)) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Expense_Report)) {
        requiredSectionsForPageBreak.push(DocumentParts.Expense_Report);
      }
    }

    // Check if Hazard Analysis data exists and dynamically include it
    if (reportPdfResponse4?.Hazard_Analysis?.length > 0) {
      if (!requiredSectionsForPageBreak.includes(DocumentParts.Hazard_Analysis)) {
        requiredSectionsForPageBreak.push(DocumentParts.Hazard_Analysis);
      }
    }

    // If no sections are selected, no page break is needed
    if (checkedSectionss.length === 0) {
      return false;
    }

    // Ensure that at least one of the required sections is selected
    const hasRequiredSections = checkedSectionss.some((selectedSection) => requiredSectionsForPageBreak.includes(selectedSection));

    // If required sections are not selected, no page break is needed
    if (!hasRequiredSections) {
      return false;
    }

    // Check if the current section is selected
    const isSectionSelected = checkedSectionss.includes(section);

    // If the current section is not selected, no page break is needed
    if (!isSectionSelected) {
      return false;
    }

    // Default: return true if all conditions are met
    return true;
  };

  return (
    <div ref={ref} style={{ margin: '8px' }} className="printable-content">
      <div className="w100">
        <FirstPageSection />
        {(shouldAddPageBreakJobOrder(DocumentParts.Job_Order, checkedSections) || (checkedSections?.includes(DocumentParts.Table_of_Contents) && ShowTableContent(Number(DocumentParts.Job_Order)))) && <div className="page-break" />}
        { checkedSections?.includes(DocumentParts.Table_of_Contents) && ShowTableContent(Number(DocumentParts.Job_Order))
       && (
       <>
         <TableContentSection />
         {shouldAddPageBreakJobOrder(DocumentParts.Job_Order, checkedSections) && !checkedSections?.includes(DocumentParts.Tube_Charts) && <div className="page-break" />}
       </>
       )}
        { checkedSections?.includes(DocumentParts.Job_Order)
       && (
       <>
         <ComponentJobOrder />
         {/* {checkArray(Number(DocumentParts.Job_Order)) && <div className="page-break" />} */}
         {shouldAddPageBreakJobOrder(DocumentParts.Job_Order, checkedSections) && <div className="page-break" />}
       </>
       )}

        { checkedSections?.includes(DocumentParts.Vessel_Information)
        && (
        <>
          <VesselInfoSection />
          {/* {checkArray(Number(DocumentParts.Vessel_Information)) && <div className="page-break" />} */}
          <div className="page-break" />
          <VesselBayLengthInfoSection />
          {/* {checkArray(Number(DocumentParts.Vessel_Information)) && <div className="page-break" />} */}
          {shouldAddPageBreakVesselInformation(DocumentParts.Vessel_Information, checkedSections) && <div className="page-break" />}
        </>
        )}
        {checkedSections?.includes(DocumentParts.Defect_Graph)
        && (
        <>
          <BartChartNewSection />
          {shouldAddPageBreakDefectGraph(DocumentParts.Defect_Graph, checkedSections) && <div className="page-break" />}
        </>
        )}
        {/* {(checkedSections?.includes(DocumentParts.Defect_Graph) && reportPdfResponse1?.Comparison_Graphs?.length > 0) && (
          <>
            <BartChartNewSection />
            {(checkArray(Number(DocumentParts.Defect_Graph)) || reportPdfResponse1?.Comparison_Graphs?.length > 1) && <div className="page-break" />}
          </>
        )} */}

        {(checkedSections?.includes(DocumentParts.Summary_of_Inspection) && reportPdfResponse2?.Summary_of_Inspection?.length > 0)
          && (
          <>
            <SummaryInspectionSection />
            {shouldAddPageBreakSummary(DocumentParts.Summary_of_Inspection, checkedSections) && <div className="page-break" />}
          </>
          )}

        {(checkedSections?.includes(DocumentParts.Recommendations)
            && reportPdfResponse2?.Recommendations?.[0]?.Recommendation?.length > 0) && (
            <>
              <RecommendationSection />
              {shouldAddPageBreakRecommendations(DocumentParts.Recommendations, checkedSections) && <div className="page-break" />}
            </>
        )}

        {checkedSections?.includes(DocumentParts.Data_Sheets) && reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length > 0 && (
          <>
            <DataSheetSection />
            {shouldAddPageBreakDataSheet(DocumentParts.Data_Sheets, checkedSections) && <div className="page-break" />}
          </>
        )}

        {checkedSections?.includes(DocumentParts.Tube_Charts) && <TubeBundleLayoutSection />}
        {(checkedSections?.includes(DocumentParts.Photos) && reportPdfResponse3?.Photos?.length > 0) && (
          <>
            <ComponentPhotos />
            {shouldAddPageBreakPhotos(DocumentParts.Photos, checkedSections) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Strip_Charts) && reportPdfResponse3?.Calibration_Strip_Charts?.length > 0 && (
        <>
          <StripChartSection data={reportPdfResponse3?.Calibration_Strip_Charts} showTable={reportPdfResponse3?.Calibration_Strip_Charts?.length > 0} label="Strip Chart" />
          {shouldAddPageBreakStripChart(DocumentParts.Strip_Charts, checkedSections) && <div className="page-break" />}
        </>
        ) }
        {checkedSections?.includes(DocumentParts.Strip_Charts) && reportPdfResponse3?.Tube_Strip_Charts?.length > 0 && (
          <>
            <StripChartSection data={reportPdfResponse3?.Tube_Strip_Charts} showTable={reportPdfResponse3?.Calibration_Strip_Charts?.length === 0} label="Strip Chart" />
            {shouldAddPageBreakStripChart(DocumentParts.Strip_Charts, checkedSections) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Phase_Charts) && reportPdfResponse4?.Phase_Charts?.length > 0 && (
          <>
            <LineChartSection />
            {shouldAddPageBreakPhaseChart(DocumentParts.Phase_Charts, checkedSections) && <div className="page-break" />}
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Calibration_Procedure) && reportPdfResponse4?.Caliberation_Procedure !== null && (
          <>
            <CalibrationProcedureSection />
            {/* {checkArray(Number(DocumentParts.Calibration_Procedure)) && <div className="page-break" />} */}
            {shouldAddPageBreakCalibrationProcedure(DocumentParts.Calibration_Procedure, checkedSections) && <div className="page-break" />}
          </>
        )}
        {checkedSections?.includes(DocumentParts.Inspection_Procedure) && reportPdfResponse4?.Inspection_Procedure !== null && (
        <>
          <InspectionProcedure />
          {/* {(checkArray(Number(DocumentParts.Inspection_Procedure)) || checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations)) && <div className="page-break" />} */}
          {(shouldAddPageBreakInspectionProcedure(DocumentParts.Inspection_Procedure, checkedSections) || checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations)) && <div className="page-break" />}
        </>
        )}
        {checkedSections?.includes(DocumentParts.Hazard_Analysis) && reportPdfResponse4?.Hazard_Analysis?.length > 0 && (
        <>
          <HazardAnalysis />
          {(checkArray(Number(DocumentParts.Hazard_Analysis)) || checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations)) && <div className="page-break" />}
        </>
        )}
        {/* {checkedSections?.includes(DocumentParts.Expense_Report) && (
          <>
            <ExpenseReport />
            {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && <div className="page-break" />}
          </>
        )} */}
        {checkedSections?.includes(DocumentParts.Expense_Report)
  && reportPdfResponse4.Expense_Report?.some(
    (report) => report.ExpenseReports?.length > 0,
  ) && (
    <>
      <ExpenseReport />
      {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && (
        <div className="page-break" />
      )}
    </>
        )}
        {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && (
        <AbbreviationsSection />
        )}
      </div>
    </div>
  );
});

export default ComponentToPrint;
