import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../index.module.scss';
import { DeleteIcon, EditIcon } from '../../../../assets/TableIcons';
import UserIcon from '../../../../assets/Icons/userIcon.svg';
import {
  deleteBookMarkStart,
  setShowBookmarkModals,
} from '../../redux/calenderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import AddBookmarkModal from '../addBookmarkModal';
import { RootState } from '../../../../redux/rootState';
import { DeleteBookMarkPayload } from '../../utils/types';

function componentEditBookmark() {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bookMarkId, setBookmarkId] = useState('');
  const {
    showBookmarkModal, selectedBookmark, selectedYear, selectedMonth, selectedWeek,
  } = useSelector((state:RootState) => state.CalenderReducer);
  const handleEdit = () => {
    dispatch(setShowBookmarkModals(true));
    // dispatch(setSelectedEvent(detail));
  };

  const handleDelete = () => {
    const payload: DeleteBookMarkPayload = {
      id: String(bookMarkId) || '',
      month: selectedMonth,
      year: selectedYear,
      week: selectedWeek,
    };

    if (bookMarkId) {
      dispatch(deleteBookMarkStart(payload));
    }
    setShowDeleteModal(false);
    // dispatch(setSelectedDeleteEventId());
  };

  const handleShowDelete = (id: number | undefined) => {
    setBookmarkId(String(id));
    setShowDeleteModal(true);
    // dispatch(setSelectedDeleteEventId());
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setBookmarkId('');
    // dispatch(setSelectedDeleteEventId(''));
  };

  return (
    <>
      <div className={styles.calendarTabContainer}>
        <div className={styles.tabAppointmentList}>
          <div className={styles.tabAppointmentTitle}>{selectedBookmark?.AnalystName}</div>
          <div className={styles.tabAppointmentDateRange}>{`${selectedBookmark?.Date}`}</div>
          <div className={styles.tabAppointmentActionBox}>
            <div className={styles.tabAppointmentContact}>
              <img src={UserIcon} alt="icon" width={18} height={18} />
              Bookmark
            </div>
            <div className={styles.tabAppointmentActionButton}>
              <span onClick={() => handleEdit()}><EditIcon /></span>
              <span onClick={() => handleShowDelete(selectedBookmark?.Id)}><DeleteIcon /></span>
            </div>
          </div>
        </div>
      </div>
      {showBookmarkModal && (
      <AddBookmarkModal
        show={showBookmarkModal}
        handleClose={() => dispatch(setShowBookmarkModals(false))}
      />
      )}
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default componentEditBookmark;
