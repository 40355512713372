import {
  Button, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import RequiredFiled from '../../../../Common/RequiredField';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { UnitInfoOrderFormValues } from '../../utils/types';
import { CLIENT_SITE_TAB, JobOrderMainDefaultValue, UnitInfoOrderFormNames } from '../../utils/constants';
import { RootState } from '../../../../redux/rootState';
import { pctRegex } from '../../../../utils/regex';
import { AddPlaylist, DeleteIcon } from '../../../../assets/TableIcons';
import styles from '../../utils/style.module.scss';
import { getSerialNoStart, getUnitMasterStart, getVesselMasterStart } from '../../../Job/ScreenUnitInformation/redux/unitInfoSlice';
import { getAnalystMasterStart } from '../../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import { CommonDateInput } from '../../../../Common/CommonDateInput';
import { assignJobOrderMain, assignMain, jobOrderMainSchema } from '../../utils/helper';
import { setLoader } from '../../../../redux/loaderSlice';
import {
  getJobOrderById, jobOrderMainForm, resetJobOrderMain, setSelectedTab,
} from '../../redux/sliceJobOrder';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';
import EndPoints from '../../../../Routes/EndPoints';
import ComponentSelectInputInspectedBy from '../ComponentSelectInputInspectedBy';

function ScreenMain() {
  const {
    control, watch, formState: { errors }, handleSubmit, setValue, reset, trigger,
  } = useForm<UnitInfoOrderFormValues>({
    defaultValues: JobOrderMainDefaultValue,
    resolver: yupResolver(jobOrderMainSchema as unknown as ObjectSchema<UnitInfoOrderFormValues>),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();

  const {
    unitMaster, machineType,
  } = useSelector((state: RootState) => state.unitInfoReducer);
  const { analystList } = useSelector((state: RootState) => state.bidsReducer);
  const { JobOrderMainData, isLoading, mainFormData } = useSelector((state: RootState) => state.jobOrderReducer);
  const testType = watch(UnitInfoOrderFormNames.testType);
  const spotTest = watch(UnitInfoOrderFormNames.spotTest);
  // const [inspectedByFieldsArray, setinspectedByFieldsArray] = useState([]);
  const onSubmit = (values: UnitInfoOrderFormValues) => {
    dispatch(jobOrderMainForm(values));
    dispatch(setSelectedTab(CLIENT_SITE_TAB));
    reset();
  };

  // const handleChange = () => {
  //   const values = getValues();
  //   dispatch(jobOrderMainForm(values));
  // };

  useEffect(() => {
    dispatch(getUnitMasterStart());
    dispatch(getAnalystMasterStart());
    dispatch(getVesselMasterStart());
    return () => {
      dispatch(resetJobOrderMain());
    };
  }, []);

  const handleTestTypeChange = (value: string) => {
    setValue(UnitInfoOrderFormNames.odTubes, '');
    if (value !== 'FLT') {
      trigger(UnitInfoOrderFormNames.odTubes);
    }
  };

  const handlePCTChange = (value : boolean) => {
    setValue(UnitInfoOrderFormNames.pct, '');
    if (value === false) {
      trigger(UnitInfoOrderFormNames.pct);
    }
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    // Check if form data is already available in Redux
    if (orderId && Object.keys(mainFormData).length === 0) {
      dispatch(getJobOrderById({ id: orderId }));
    }
  }, [mainFormData, orderId]);

  const { fields, append, remove } = useFieldArray<Empty>({
    control,
    name: UnitInfoOrderFormNames.inspectedBy,
  });

  useEffect(() => {
    if (mainFormData && Object.keys(mainFormData).length > 0) {
      assignMain(setValue, mainFormData);
      setValue(UnitInfoOrderFormNames.inspectedBy, mainFormData.inspectedBy);
    } else if (JobOrderMainData) {
      // dispatch(jobOrderMainForm(watch()));
      const filteredData = JobOrderMainData?.InspectedBy?.map((name) => (analystList.some((item) => item.Text === name) ? name : ''));
      assignJobOrderMain(setValue, JobOrderMainData);
      setValue(UnitInfoOrderFormNames.inspectedBy, filteredData);
    }
  }, [JobOrderMainData, mainFormData, analystList]);

  useEffect(() => {
    dispatch(getSerialNoStart());
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '20px' }}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Test
            </div>
            <div className="double_checkbox">
              <CustomCheckbox
                control={control}
                name={UnitInfoOrderFormNames.readyToSend}
                // handleChange={handleChange}
                label="Ready To Send"
              />
              <CustomCheckbox
                control={control}
                name={UnitInfoOrderFormNames.ASMESection}
                label="ASME Section V"
                // handleChange={handleChange}
                defaultValue={false}
              />
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={9} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">&nbsp;</label>
                  <Controller
                    name={UnitInfoOrderFormNames.testType}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <FormControl>
                        <RadioGroup
                          row
                          value={testType}
                          key={testType}
                          defaultValue={UnitInfoOrderFormNames.flux}
                          onChange={(event) => {
                            onChange(event);
                            handleTestTypeChange(event.target.value);
                            // handleChange();
                          }}
                        >
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoOrderFormNames.crossaxial}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Crossaxial</span>}
                            />
                          </div>
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoOrderFormNames.flux}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Flux</span>}
                            />
                          </div>
                          <div className="type_of_test">
                            <FormControlLabel
                              labelPlacement="start"
                              value={UnitInfoOrderFormNames.absolute}
                              control={<Radio color="success" />}
                              label={<span className="radio_button_style">Absolute</span>}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                </div>
              </Grid>
              {
                testType === UnitInfoOrderFormNames.flux ? (
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">
                        OD of Tubes
                        <RequiredFiled />
                      </label>
                      <ComponentTextInput
                        control={control}
                        name={UnitInfoOrderFormNames.odTubes}
                        regEx={/^\d+(\.\d{0,2})?$/}
                        id={UnitInfoOrderFormNames.odTubes}
                        size="small"
                        errors={errors}
                      // handleChange={handleChange}
                      />
                    </div>
                  </Grid>
                ) : ''
              }
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '20px' }}>
        <Card>
          <div className="double_checkbox_container">
            <CustomCheckbox name={UnitInfoOrderFormNames.leak} label="Leak" control={control} />
            <CustomCheckbox name={UnitInfoOrderFormNames.outside} label="Outside" control={control} />
            <CustomCheckbox name={UnitInfoOrderFormNames.safetyClass} label="Safety Class / Gear" control={control} />
            <CustomCheckbox isDisable name={UnitInfoOrderFormNames.testedBefore} label="Tested Before" control={control} />
            <CustomCheckbox name={UnitInfoOrderFormNames.informedCust} label="Informed  Cust" control={control} />
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Start Date
                  </label>
                  <CommonDateInput
                    control={control}
                    errors={errors}
                    // handleChange={handleChange}
                    name={UnitInfoOrderFormNames.startDate}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    End Date
                  </label>
                  <CommonDateInput
                    control={control}
                    errors={errors}
                    // handleChange={handleChange}
                    name={UnitInfoOrderFormNames.endDate}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Job Time </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={UnitInfoOrderFormNames.jobTime}
                    id={UnitInfoOrderFormNames.jobTime}
                    // handleChange={handleChange}
                    regEx={/^[A-Za-z ]{1,10}$/}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12} style={{ display: 'none' }}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Re-Test Date
                  </label>
                  <CommonDateInput
                    control={control}
                    errors={errors}
                    // handleChange={handleChange}
                    name={UnitInfoOrderFormNames.reTestDate}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Location (Building)
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={UnitInfoOrderFormNames.location}
                    id={UnitInfoOrderFormNames.location}
                    // handleChange={handleChange}
                    className="nonCapitalizeField"
                    regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,35}$/}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Send Report To</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    // handleChange={handleChange}
                    name={UnitInfoOrderFormNames.sendReportTo}
                    id={UnitInfoOrderFormNames.sendReportTo}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '20px' }}>
        <Card>
          <div className="double_checkbox_container">
            <CustomCheckbox name={UnitInfoOrderFormNames.serialVerified} label="Serial# is verified" control={control} />
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Manufacturer
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoOrderFormNames.manufacturer}
                    size="small"
                    errors={errors}
                    // handleChange={handleChange}
                    entries={unitMaster?.Manufacture || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Machine Type
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    errors={errors}
                    name={UnitInfoOrderFormNames.machineType}
                    size="small"
                    // handleChange={handleChange}
                    entries={machineType}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Model
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={UnitInfoOrderFormNames.model}
                    // handleChange={handleChange}
                    errors={errors}
                    regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,10}$/}
                    id={UnitInfoOrderFormNames.model}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Type</label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoOrderFormNames.type}
                    // handleChange={handleChange}
                    size="small"
                    entries={unitMaster?.Type || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Style</label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoOrderFormNames.style}
                    size="small"
                    // handleChange={handleChange}
                    entries={unitMaster?.Style || []}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Serial #
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={UnitInfoOrderFormNames.serialNumber}
                    id={UnitInfoOrderFormNames.serialNumber}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 10 }}
                    // handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Serial # on Site
                  </label>
                  <ComponentTextInput
                    control={control}
                    name={UnitInfoOrderFormNames.serialNumberSite}
                    id={UnitInfoOrderFormNames.serialNumberSite}
                    className="nonCapitalizeField"
                    errors={errors}
                    inputProps={{ maxLength: 10 }}
                    // handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Expenses
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={UnitInfoOrderFormNames.expenses}
                    id={UnitInfoOrderFormNames.expenses}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 10 }}
                    // handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    By
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={UnitInfoOrderFormNames.by}
                    id={UnitInfoOrderFormNames.by}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 10 }}
                    // handleChange={handleChange}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Reviewed By</label>
                  <ComponentSelectInput
                    control={control}
                    name={UnitInfoOrderFormNames.reviewedBy}
                    size="small"
                    // handleChange={handleChange}
                    entries={[]}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}> </Grid>
              {fields?.map((data, i) => (
                <Grid item md={3} sm={6} xs={12} key={data?.id} className="d-flex">
                  <div className="div_label_text width-90 mr10">
                    <label className="label_Style_job">
                      Inspected By
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInputInspectedBy
                      control={control}
                      name={`${UnitInfoOrderFormNames.inspectedBy}.${i}`}
                      fieldName={UnitInfoOrderFormNames.inspectedBy}
                      size="small"
                      errors={errors}
                      i={i}
                      // required
                      // handleChange={handleChange}
                      entries={analystList || []}
                    />
                  </div>
                  <div className={styles.AddPlaylistStyle} onClick={() => remove(i)} style={{ cursor: 'pointer' }}>
                    <DeleteIcon />
                  </div>
                </Grid>
              ))}
              <Grid item md={12} sm={12} xs={12}>
                <div
                  className={styles.AddPlaylistStyle}
                  onClick={() => append('')}
                  style={{ cursor: 'pointer', marginTop: '0px', maxWidth: '25px' }}
                >
                  <AddPlaylist />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12} style={{ marginBottom: '24px' }}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Spot Test
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="unit_test_style">
                  <div>
                    <label>&nbsp;</label>
                    <CustomCheckbox
                      name={UnitInfoOrderFormNames.spotTest}
                      handleChange={handlePCTChange}
                      label="Spot Test"
                      control={control}
                    />
                  </div>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Pct.
                      {spotTest && <RequiredFiled />}
                    </label>
                    <ComponentTextInput
                      control={control}
                      regEx={pctRegex}
                      type="number"
                      name={UnitInfoOrderFormNames.pct}
                      id={UnitInfoOrderFormNames.pct}
                      isDisable={!spotTest}
                      errors={errors}
                      onKeyDown={(e: { key: string; preventDefault: () => void; }) => {
                        if (['e', 'E', '-', '+'].includes(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      // handleChange={handleChange}
                      size="small"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => navigate(`/${EndPoints.JOB_ORDER}`)}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next">Save & Next</Button>
          </div>
        </div>
      </Grid>
    </form>
  );
}

export default ScreenMain;
