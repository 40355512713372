import {
  TextField, IconButton, Grid, Card, Tooltip, Button,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import ComponentSiteInfo from './siteInfo';
import { CreateJobPayload, CustomerSiteFormInfo } from '../../utils/types';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { CustomerSiteFormFields } from '../../utils/constants';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../../redux/rootState';
import AddCustomerModal from '../../../../../../Common/AddCustomerModal';
import { getCustomerByIdStart } from '../../../../../addCustomer/redux/addCustomerSlice';
import { assignCustomerValues, editSitePayload } from '../../utils/helper';
import { createJobStart, getJobBidsStart, updateJobStart } from '../../redux/addJobSlice';
import { extRegex, phoneRegex } from '../../../../../../utils/regex';
import customerSiteFormSchema from '../../utils/validations';
import { scrollToErrorField, stripCustPrefix } from '../../../../../../utils/CommonFunctions';
import DataTable from '../../../../../../Common/DataTable';
import { JobBidTableColumns } from './addSiteModal/utils/constants';
import { JobBidData } from './addSiteModal/utils/Type';
import { updateSiteByIdStart } from '../../../../../Sites/redux/sliceSites';

export default function ComponentCustomerInfo() {
  const [open, setOpen] = useState(false);
  const {
    control, setValue, handleSubmit, getValues, trigger, watch, formState: { errors, isDirty },
  } = useForm<CustomerSiteFormInfo>({
    resolver: yupResolver(customerSiteFormSchema as ObjectSchema<CustomerSiteFormInfo>),
  });
  const { phoneCodes, countries } = useSelector((state: RootState) => state.masterDataReducer);
  const { selectedJob, jobId: selectedJobId } = useSelector((state: RootState) => state.jobStarterReducer);
  const { otherCustomerdetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const { jobId, customerId } = useParams();
  const { jobBidsList, totalEntries, selectedSite } = useSelector((state: RootState) => state.jobStarterReducer);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  const formRef = useRef(null);

  const handleData = () => {
    const params = {
      siteId: selectedSite?.SiteId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getJobBidsStart(params));
  };

  useEffect(() => {
    handleData();
  }, [selectedSite]);

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      scrollToErrorField(errorField);
    }
  }, [errors]);

  const handleAction = () => {
    setOpen(!open);
  };

  const tableData: JobBidData[] = useMemo(() => jobBidsList.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      Date: item?.BidCreationDate,
      Job: item?.JobId,
      Site: item?.SiteName,
      Cust: stripCustPrefix(item?.CustomerId),
      TotalBid: item?.TotalBid,
      id: String(item?.AnalystId),
    };
    return transformedDataItem;
  }), [jobBidsList]);

  const handleTabClick = (custId: string) => {
    const payload = {
      customerId: custId,
      otherCustomer: true,
    };
    setValue(CustomerSiteFormFields.CustomerId, stripCustPrefix(custId));
    trigger(CustomerSiteFormFields.CustomerId);
    dispatch(getCustomerByIdStart(payload));
  };

  useEffect(() => {
    if (otherCustomerdetails?.CompanyInformation) {
      assignCustomerValues(setValue, otherCustomerdetails);
    }
  }, [otherCustomerdetails]);

  useEffect(() => {
    if (customerId) {
      setValue(CustomerSiteFormFields.CustomerId, stripCustPrefix(customerId));
    }
  }, [customerId]);

  const onSubmit = (values: CustomerSiteFormInfo) => {
    const payload: CreateJobPayload = {
      CustId: `Cust-${values.customerId}` || selectedJob?.JobInfo?.CustId,
      SiteId: values.siteId,
      CallerContact: values.contact ? values.contact : '',
      CallerCell: values.cell,
      CallerPhoneCode: values.customerPhoneCode,
      CallerPhone: values.phone,
    };
    if (isDirty) {
      const sitePayload = editSitePayload(values, selectedSite);
      dispatch(updateSiteByIdStart(sitePayload));
    }

    if (jobId || selectedJobId) {
      dispatch(updateJobStart({ ...payload, JobId: selectedJobId }));
    } else {
      dispatch(createJobStart(payload));
    }
  };

  useEffect(() => {
    if (selectedJob?.JobInfo?.JobId) {
      setValue(CustomerSiteFormFields.Cell, selectedJob?.JobInfo?.CallerCell);
      setValue(CustomerSiteFormFields.CustomerId, stripCustPrefix(selectedJob?.JobInfo?.CustId));
      setValue(CustomerSiteFormFields.Contact, selectedJob?.JobInfo?.CallerContact);
      setValue(CustomerSiteFormFields.PhoneCode, selectedJob?.JobInfo?.CallerPhoneCode);
      setValue(CustomerSiteFormFields.Phone, selectedJob?.JobInfo?.CallerPhone);
    }
  }, [selectedJob]);

  const handleOnChange = (text: string, onChange: (event: string) => void, value: string) => {
    if (value?.length > 0) {
      return;
    }
    onChange('');
  };

  return (
    <div className="div_container_CustomerInfo">
      <form ref={formRef}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_container">
                <div className="customerInfo">
                  Customer Information
                </div>
                <Controller
                  control={control}
                  name={CustomerSiteFormFields.CustomerId}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      placeholder="Customer ID *"
                      value={value}
                      disabled={Boolean(jobId)}
                      onChange={(text) => handleOnChange(text.currentTarget.value, onChange, value)}
                      onClick={jobId ? () => {} : handleAction}
                      id={CustomerSiteFormFields.CustomerId}
                      error={!!errors.customerId}
                      helperText={errors?.customerId?.message}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Search here..">
                            <IconButton
                              onClick={handleAction}
                              disabled={Boolean(jobId)}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                      className="mob-wd-100"
                    />
                  )}
                />
              </div>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Customer</label>
                      <ComponentTextInput
                        control={control}
                        capitalizeFirst
                        isDisable
                        name={CustomerSiteFormFields.CustomerName}
                        id={CustomerSiteFormFields.CustomerName}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Contact</label>
                      <ComponentTextInput
                        control={control}
                        name={CustomerSiteFormFields.CustomerContact}
                        isDisable
                        id={CustomerSiteFormFields.CustomerContact}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Address</label>
                      <ComponentTextInput
                        control={control}
                        className="nonCapitalizeField"
                        name={CustomerSiteFormFields.CustomerAddress}
                        isDisable
                        id={CustomerSiteFormFields.CustomerAddress}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Address (Optional)</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        className="nonCapitalizeField"
                        name={CustomerSiteFormFields.CustomerOptionalAdress}
                        id={CustomerSiteFormFields.CustomerOptionalAdress}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Zip</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={CustomerSiteFormFields.CustomerZip}
                        id={CustomerSiteFormFields.CustomerZip}
                        regEx={extRegex}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4.5} sm={4.5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">State</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={CustomerSiteFormFields.CustomerState}
                        id={CustomerSiteFormFields.CustomerState}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4.5} sm={4.5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">City</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={CustomerSiteFormFields.CustomerCity}
                        id={CustomerSiteFormFields.CustomerCity}
                        size="small"
                      />
                    </div>
                  </Grid>

                  <Grid item md={2} sm={2} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Country</label>
                      <ComponentSelectInput
                        name={CustomerSiteFormFields.CustomerCountry}
                        control={control}
                        defaultValue=""
                        disabled
                        size="small"
                        entries={countries}
                      />
                    </div>
                  </Grid>

                  <Grid item md={2} sm={2} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Code</label>
                      <ComponentSelectInput
                        name={CustomerSiteFormFields.CustomerPhoneCode}
                        control={control}
                        defaultValue=""
                        disabled
                        size="small"
                        entries={phoneCodes}
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} sm={5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Phone</label>
                      <ComponentTextInput
                        control={control}
                        name={CustomerSiteFormFields.CustomerPhone}
                        id={CustomerSiteFormFields.CustomerPhone}
                        regEx={phoneRegex}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <div style={{ padding: '20px 0px' }}>
                      <div className="contact_info_text">Contact Information</div>
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Contact</label>
                      <ComponentTextInput
                        control={control}
                        regEx={/^[A-Za-z ]+$/}
                        inputProps={{ maxLength: 50 }}
                        name={CustomerSiteFormFields.Contact}
                        id={CustomerSiteFormFields.Contact}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} sm={5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Cell</label>
                      <ComponentTextInput
                        control={control}
                        name={CustomerSiteFormFields.Cell}
                        inputProps={{ maxLength: 16 }}
                        defaultValue=""
                        id={CustomerSiteFormFields.Cell}
                        regEx={phoneRegex}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} sm={2} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Code</label>
                      <ComponentSelectInput
                        name={CustomerSiteFormFields.CustomerPhoneCode}
                        control={control}
                        defaultValue="+1"
                        size="small"
                        entries={phoneCodes}
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} sm={5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Phone</label>
                      <ComponentTextInput
                        control={control}
                        name={CustomerSiteFormFields.Phone}
                        id={CustomerSiteFormFields.Phone}
                        defaultValue=""
                        inputProps={{ maxLength: 16 }}
                        regEx={phoneRegex}
                        size="small"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <ComponentSiteInfo
              control={control}
              getValues={watch}
              setValue={setValue}
              trigger={trigger}
              isDisabled={!getValues(CustomerSiteFormFields.CustomerId) && !jobId}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="button_save_and_next">Save & Next</Button>
            </div>
          </Grid>
        </Grid>
      </form>
      {open && (
      <AddCustomerModal
        show={open}
        setOpenFrom={handleAction}
        handleTabCell={handleTabClick}
      />
      )}
      {selectedSite?.SiteId && !jobId
        ? (
          <>
            <div className="bidsSiteDiv">Previous Bids On This Site</div>
            <DataTable<JobBidData>
              data={tableData}
              columns={JobBidTableColumns}
              totalRecords={totalEntries}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
            />
          </>
        ) : ''}
    </div>
  );
}
