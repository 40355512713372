/* eslint-disable no-mixed-operators */
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  ClientSiteFormValues, CustomerData, GetUnitPriceJobOrderResponse, JobOrderMainData, JobOrderPricingData,
  OtherVesselJobOrder, ResultDataById, SiteDataTypes, UnitInfoOrderFormValues, VesselDataJobOrder, VesselJobOrder, VesselsInfoData,
} from './types';
import { JobOrderSiteFormFields, UnitInfoOrderFormNames } from './constants';

const startOfQ11990 = dayjs('1990-01-01T00:00:00.000');

export const jobOrderMainSchema = Yup.object({
  readyToSend: Yup.boolean(),
  ASMESection: Yup.boolean(),
  testType: Yup.string(),
  odTubes: Yup.string().test({
    name: 'required-with-testType',
    test(value) {
      const { testType } = this.parent;
      if (testType === 'FLT') {
        return !!value || this.createError({
          message: 'OD of Tubes is required',
        });
      }
      return true;
    },
  }),

  leak: Yup.boolean(),
  outside: Yup.boolean(),
  safetyClass: Yup.boolean(),
  testedBefore: Yup.boolean(),
  informedCust: Yup.boolean(),
  startDate: Yup.date()
    .nullable()
    .min(startOfQ11990, 'Date must be on or after 1990-01-01'),
  endDate: Yup.date().nullable()
    .min(startOfQ11990, 'Date must be on or after 1990-01-01'),
  jobTime: Yup.string(),
  reTestDate: Yup.date().nullable()
    .min(startOfQ11990, 'Date must be on or after 1990-01-01'),
  location: Yup.string(),
  sendReportTo: Yup.string(),

  serialVerified: Yup.boolean(),
  manufacturer: Yup.string().required('Manufacturer is required'),
  machineType: Yup.string().required('Machine Type is required'),
  model: Yup.string().required('Model is required'),
  serialNumber: Yup.string(),
  serialNumberSite: Yup.string(),
  expenses: Yup.string(),
  by: Yup.string(),
  reviewedBy: Yup.string(),
  spotTest: Yup.boolean(),
  pct: Yup.string().test({
    name: 'required-with-spotTest',
    test(value) {
      const { spotTest } = this.parent;
      if (spotTest) {
        return !!value && parseFloat(value) !== 0 || this.createError({
          message: 'Pct. must be non-zero',
        });
      }

      return true;
    },
  }),
  inspectedBy: Yup.array()
    .of(Yup.string().required('Inspected By is required')),
  // .min(1, 'At least one Inspected By entry is required'),
});

export const assignJobOrderMain = (setValue: SetValue, MainInfo: JobOrderMainData) => {
  const startDate = MainInfo.StartDate ? dayjs(new Date(MainInfo.StartDate)) : null;
  setValue(UnitInfoOrderFormNames.startDate, startDate);
  const endDate = MainInfo.EndDate ? dayjs(new Date(MainInfo.EndDate)) : null;
  setValue(UnitInfoOrderFormNames.endDate, endDate);
  const reTestDate = MainInfo.ReTestDate ? dayjs(new Date(MainInfo.ReTestDate)) : null;
  setValue(UnitInfoOrderFormNames.reTestDate, reTestDate);
  [
    { name: UnitInfoOrderFormNames.readyToSend, value: MainInfo?.ReadyToSend },
    { name: UnitInfoOrderFormNames.ASMESection, value: MainInfo?.AsmeSectionV },
    { name: UnitInfoOrderFormNames.testType, value: MainInfo?.TestType },
    { name: UnitInfoOrderFormNames.odTubes, value: MainInfo?.OdOfTube },
    { name: UnitInfoOrderFormNames.leak, value: MainInfo?.Leak },
    { name: UnitInfoOrderFormNames.outside, value: MainInfo?.Outside },
    { name: UnitInfoOrderFormNames.safetyClass, value: MainInfo?.SafetyClassGear },
    { name: UnitInfoOrderFormNames.testedBefore, value: MainInfo?.TestedBefore },
    { name: UnitInfoOrderFormNames.informedCust, value: MainInfo?.InformedCustomer },
    { name: UnitInfoOrderFormNames.jobTime, value: MainInfo?.JobTime },
    { name: UnitInfoOrderFormNames.location, value: MainInfo?.Location },
    { name: UnitInfoOrderFormNames.sendReportTo, value: MainInfo?.SendReportTo },
    { name: UnitInfoOrderFormNames.serialVerified, value: MainInfo?.SerialNumberIsVerified },
    { name: UnitInfoOrderFormNames.manufacturer, value: MainInfo?.ManufactureId },
    { name: UnitInfoOrderFormNames.machineType, value: MainInfo?.MachineTypeId },
    { name: UnitInfoOrderFormNames.model, value: MainInfo?.Model },
    { name: UnitInfoOrderFormNames.type, value: MainInfo?.TypeId },
    { name: UnitInfoOrderFormNames.style, value: MainInfo?.StyleId },
    { name: UnitInfoOrderFormNames.serialNumber, value: MainInfo?.SerialNumber },
    { name: UnitInfoOrderFormNames.serialNumberSite, value: MainInfo?.SerialNumberOnSite },
    { name: UnitInfoOrderFormNames.expenses, value: MainInfo?.Expenses },
    { name: UnitInfoOrderFormNames.by, value: MainInfo?.By },
    { name: UnitInfoOrderFormNames.reviewedBy, value: MainInfo?.ReviewedBy },
    // { name: UnitInfoOrderFormNames.inspectedBy, value: MainInfo?.InspectedBy },
    { name: UnitInfoOrderFormNames.spotTest, value: MainInfo?.SpotTest },
    { name: UnitInfoOrderFormNames.pct, value: MainInfo?.SpotPercent },
  ].forEach(({ name, value }) => setValue(name, value));
};
export const assignMain = (setValue: SetValue, mainFormData: UnitInfoOrderFormValues) => {
  const startDate = mainFormData.startDate ? dayjs(mainFormData.startDate) : null;
  setValue(UnitInfoOrderFormNames.startDate, startDate);

  const endDate = mainFormData.endDate ? dayjs(mainFormData.endDate) : null;
  setValue(UnitInfoOrderFormNames.endDate, endDate);

  const reTestDate = mainFormData.reTestDate ? dayjs(mainFormData.reTestDate) : null;
  setValue(UnitInfoOrderFormNames.reTestDate, reTestDate);

  [
    { name: UnitInfoOrderFormNames.readyToSend, value: mainFormData.readyToSend },
    { name: UnitInfoOrderFormNames.ASMESection, value: mainFormData.ASMESection },
    { name: UnitInfoOrderFormNames.testType, value: mainFormData.testType },
    { name: UnitInfoOrderFormNames.odTubes, value: mainFormData.odTubes },
    { name: UnitInfoOrderFormNames.leak, value: mainFormData.leak },
    { name: UnitInfoOrderFormNames.outside, value: mainFormData.outside },
    { name: UnitInfoOrderFormNames.safetyClass, value: mainFormData.safetyClass },
    { name: UnitInfoOrderFormNames.testedBefore, value: mainFormData.testedBefore },
    { name: UnitInfoOrderFormNames.informedCust, value: mainFormData.informedCust },
    { name: UnitInfoOrderFormNames.jobTime, value: mainFormData.jobTime },
    { name: UnitInfoOrderFormNames.location, value: mainFormData.location },
    { name: UnitInfoOrderFormNames.sendReportTo, value: mainFormData.sendReportTo },
    { name: UnitInfoOrderFormNames.serialVerified, value: mainFormData.serialVerified },
    { name: UnitInfoOrderFormNames.manufacturer, value: mainFormData.manufacturer },
    { name: UnitInfoOrderFormNames.machineType, value: mainFormData.machineType },
    { name: UnitInfoOrderFormNames.model, value: mainFormData.model },
    { name: UnitInfoOrderFormNames.type, value: mainFormData.type },
    { name: UnitInfoOrderFormNames.style, value: mainFormData.style },
    { name: UnitInfoOrderFormNames.serialNumber, value: mainFormData.serialNumber },
    { name: UnitInfoOrderFormNames.serialNumberSite, value: mainFormData.serialNumberSite },
    { name: UnitInfoOrderFormNames.expenses, value: mainFormData.expenses },
    { name: UnitInfoOrderFormNames.by, value: mainFormData.by },
    { name: UnitInfoOrderFormNames.reviewedBy, value: mainFormData.reviewedBy },
    // { name: UnitInfoOrderFormNames.inspectedBy, value: mainFormData.inspectedBy },
    { name: UnitInfoOrderFormNames.spotTest, value: mainFormData.spotTest },
    { name: UnitInfoOrderFormNames.pct, value: mainFormData.pct },
  ].forEach(({ name, value }) => setValue(name, value));
};
export const assignJobOrderClientSite = (setValue: SetValue, ClientData: CustomerData, SiteData: SiteDataTypes) => {
  [
    { name: JobOrderSiteFormFields.ClientId, value: ClientData?.CustomerId },
    { name: JobOrderSiteFormFields.ClientName, value: ClientData?.CustomerName },
    { name: JobOrderSiteFormFields.ClientContact, value: ClientData?.Contact },
    { name: JobOrderSiteFormFields.ClientAddress, value: ClientData?.Address },
    { name: JobOrderSiteFormFields.ClientOptionalAddress, value: ClientData?.OptionalAddress },
    { name: JobOrderSiteFormFields.ClientZip, value: ClientData?.Pincode },
    { name: JobOrderSiteFormFields.ClientState, value: ClientData?.StateName },
    { name: JobOrderSiteFormFields.ClientCity, value: ClientData?.CityName },
    { name: JobOrderSiteFormFields.ClientPhoneCode, value: ClientData?.PhoneCode },
    { name: JobOrderSiteFormFields.ClientPhone, value: ClientData?.Phone },
    { name: JobOrderSiteFormFields.SiteId, value: SiteData?.SiteId },
    { name: JobOrderSiteFormFields.SiteName, value: SiteData?.SiteName },
    { name: JobOrderSiteFormFields.SiteContact, value: SiteData?.Contact },
    { name: JobOrderSiteFormFields.SiteAddress, value: SiteData?.Address },
    { name: JobOrderSiteFormFields.SiteOptionalAddress, value: SiteData?.OptionalAddress },
    { name: JobOrderSiteFormFields.SiteCountry, value: SiteData?.SiteCountry },
    { name: JobOrderSiteFormFields.SiteZip, value: SiteData?.Pincode },
    { name: JobOrderSiteFormFields.SiteState, value: SiteData?.StateName },
    { name: JobOrderSiteFormFields.SiteCity, value: SiteData?.CityName },
    { name: JobOrderSiteFormFields.SitePhoneCode, value: SiteData?.PhoneCode },
    { name: JobOrderSiteFormFields.SitePhone, value: SiteData?.Phone },
    { name: JobOrderSiteFormFields.SiteCell, value: SiteData?.Cell },
  ].forEach(({ name, value }) => setValue(name, value));
};

export const formatJobOrderPayload = (
  Unitid: string,
  Analystid: string,
  values: VesselsInfoData,
  mainFormData: UnitInfoOrderFormValues,
  clientSiteFormData: ClientSiteFormValues,
  displayVessels: VesselDataJobOrder[],
  unitPrice: GetUnitPriceJobOrderResponse,
  numberOfTubes: number,
  tubeLength: number,
  AnalystNoteData: string[],
  newComment: string,
  SerialNo: string,
): ResultDataById => {
  const NewSerialNo = SerialNo?.split('=')[1]?.trim();

  const JobOrderMainDataApi: JobOrderMainData = {
    ReadyToSend: mainFormData.readyToSend || false,
    AsmeSectionV: mainFormData.ASMESection || false,
    TestType: mainFormData.testType, // ECT, FLT, ADT
    OdOfTube: Number(mainFormData.odTubes) || 0,
    Leak: mainFormData.leak,
    Outside: mainFormData.outside,
    SafetyClassGear: mainFormData?.safetyClass,
    TestedBefore: mainFormData.testedBefore,
    InformedCustomer: mainFormData.informedCust,
    StartDate: mainFormData.startDate ? (dayjs(mainFormData.startDate).format('DD-MM-YYYY')) : '',
    EndDate: mainFormData.endDate ? (dayjs(mainFormData.endDate).format('DD-MM-YYYY')) : '',
    JobTime: mainFormData.jobTime,
    ReTestDate: mainFormData.reTestDate ? (dayjs(mainFormData.reTestDate).format('DD-MM-YYYY')) : '',
    Location: mainFormData.location,
    SendReportTo: mainFormData.sendReportTo,
    SerialNumberIsVerified: mainFormData.serialVerified,
    ManufactureId: Number(mainFormData.manufacturer),
    MachineTypeId: Number(mainFormData.machineType),
    Model: mainFormData.model,
    TypeId: Number(mainFormData.type) === 0 ? null : Number(mainFormData.type),
    StyleId: Number(mainFormData.style) === 0 ? null : Number(mainFormData.style),
    SerialNumber: mainFormData.serialNumber || NewSerialNo,
    SerialNumberOnSite: mainFormData.serialNumberSite || NewSerialNo,
    Expenses: Number(mainFormData.expenses),
    By: mainFormData.by,
    ReviewedBy: mainFormData.reviewedBy,
    InspectedBy: mainFormData.inspectedBy,
    SpotTest: mainFormData.spotTest,
    SpotPercent: Number(mainFormData.pct),
  };

  const vessels: VesselJobOrder[] = [];
  const otherVessels: OtherVesselJobOrder[] = [];

  displayVessels.forEach((vessel: VesselDataJobOrder) => {
    if (vessel.numberOfTube > 0 || vessel.display) {
      if (vessel.id > -1) {
        vessels.push({
          VesselId: vessel.id,
          NumberOfTubes: vessel.numberOfTube,
          TubeLength: vessel.length,
        });
      } else {
        otherVessels.push({
          VesselName: vessel.vesselName,
          NumberOfTubes: vessel.numberOfTube,
          TubeLength: vessel.length,
        });
      }
    }
  });

  const vesselsPayload: VesselsInfoData = {
    TubeQtyIsEstimated: values.TubeQtyIsEstimated || false,
    TubeQtyProvidedByCustomer: values.TubeQtyProvidedByCustomer || false,
    VesselIds: vessels,
    OtherVessels: otherVessels,
  };

  const UnitPricingDataApi: JobOrderPricingData = {
    TubeCharge: unitPrice.TubeChargePrice,
    CalculatedDays: unitPrice.CalculatedDays,
    FluxLeakageDays: unitPrice.FluxLekageDays,
    FluxShipping: unitPrice.FluxShipping,
    AbsDiffShipping: unitPrice.AbsDiffShipping,
    SafetyCharges: unitPrice.SafetyChargePrice,
    TotalTubeQty: numberOfTubes,
    TotalTubeLength: tubeLength,
    MinimumJob: unitPrice.MinimumJob,
  };

  const ClientData: CustomerData = {
    CustomerId: clientSiteFormData.clientId,
    CustomerName: clientSiteFormData.clientName,
    Contact: clientSiteFormData.clientContact,
    Address: clientSiteFormData.clientAddress,
    OptionalAddress: clientSiteFormData.clientOptionalAddress,
    Pincode: clientSiteFormData.clientZip,
    CityName: clientSiteFormData.clientCity,
    PhoneCode: clientSiteFormData.clientPhoneCode,
    Phone: clientSiteFormData.clientPhone,
  };

  const SiteDataApi: SiteDataTypes = {
    SiteId: clientSiteFormData.siteId,
    SiteName: clientSiteFormData.siteName,
    Contact: clientSiteFormData.SiteContact,
    Address: clientSiteFormData.siteAddress,
    OptionalAddress: clientSiteFormData.siteOptionalAddress,
    SiteCountry: clientSiteFormData.siteCounty,
    Pincode: clientSiteFormData.siteZip,
    StateName: clientSiteFormData.siteState,
    // CityName: clientSiteFormData.siteCity,
    CityName: clientSiteFormData.siteCity === 'Other' ? clientSiteFormData?.otherSiteCity : clientSiteFormData.siteCity,
    IsOtherCity: clientSiteFormData?.siteCity === 'Other',
    PhoneCode: clientSiteFormData.sitePhoneCode,
    Phone: clientSiteFormData.sitePhone,
    Cell: clientSiteFormData.siteCell,
  };

  return {
    UnitId: Unitid,
    AnalystId: Analystid,
    JobOrderMainData: JobOrderMainDataApi,
    VesselsInfoData: vesselsPayload,
    JobOrderPricingData: UnitPricingDataApi,
    CustomerData: ClientData,
    SiteData: SiteDataApi,
    AnalystNoteData,
    Comments: newComment,
  };
};
